.chicken-carousel {

    .odd {
        @include media-breakpoint-up(md) {
            transform:translateY(25px);
        }
    }
    .even {
        @include media-breakpoint-up(md) {
            transform:translateY(-25px);
        }
    }

    .splide__slide {
        padding-top:50px;
        padding-bottom:50px;
    }

    .chicken-card {
        position:relative;
        margin:0 auto;

        &.flipped {
            .card-front {
                transform: rotateY(180deg);
                opacity:0;
            }
            .card-back {
                transform: rotateY(0deg);
                opacity:1;
            }
        }

        &:hover {
            .btn-flip-card {
                opacity:1;
                transform: translateY(0px);
            }
        }

        .card-front {
            position:relative;
            transition:all .4s ease-in-out;
        }

        .btn-flip-card {
            opacity:0;
            position: absolute;
            display: flex;
            padding: 30px 0px;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 14px;
            left: 0px;
            text-decoration: none;
            z-index: 5;
            cursor: pointer;
            height: 30px;
            top:330px;
            align-items: flex-end;
            justify-content: center;
            transition:all .2s;
            font-weight: 700;
            text-shadow: 0px 0px 20px rgba(0,0,0,1);
            transform: translateY(10px);

            @include media-breakpoint-up(md) {
                top:400px;
            }
        }

        .card-back {
            // display:none;
            width:265px;
            height: 385px;
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius:20px;
            padding:20px;
            box-shadow:0px 10px 28px rgba(0,0,0,.2);
            transform: rotateY(180deg);
            background:#fff;
            transition:all .4s ease-in-out;
            // z-index:-1;
            opacity:0;

            @include media-breakpoint-up(md) {
                width:325px;
                height: 470px;
                padding:40px;
            }
        }

        .title {
            font-size:30px;
            line-height:30px;
            @include media-breakpoint-up(md) {
                font-size:40px;
                line-height:40px;
            }
        }
        .subtitle {
            font-size:16px;
            font-weight:400;
            @include media-breakpoint-up(md) {
                font-size:20px;
            }
        }

        .carton {
            display:inline-block;
            font-size:14px;
            border-radius:10px;
            padding:4px 10px;

            &.blue {
                color:$blue;
                background:$light-blue;
            }
            &.yellow {
                color:$orange;
                background:#FFB71B;
            }
        }

        .content {

            p {
                font-size:14px;
                @include media-breakpoint-up(md) {
                    font-size:16px;
                }
                strong {font-size:14px;}
            }
        }

    }

    .speckled-legbar, .copper-maran {
        width:360px;
        .card-back {
            left:41px;
        }
        .btn-flip-card {
            width:360px;
            text-indent:0px;
        }
        @include media-breakpoint-up(md) {
            width:420px;
            .btn-flip-card {
                width:420px;
                text-indent:-15px;
            }
        }
    }
    .lohmann-brown {
        width:360px;
        .card-back {
            left:34px;
        }
        .btn-flip-card {
            width:350px;
            text-indent:-30px;
        }
        @include media-breakpoint-up(md) {
            width:450px;
            .card-back {
                left:44px;
            }
            .btn-flip-card {
                width:450px;
            }
        }

    }
    .hyline-brown {
        width:309px;
        .card-back {
            left:2px;
        }
        .btn-flip-card {
            width:309px;
        }

        @include media-breakpoint-up(md) {
            width:329px;
            .btn-flip-card {
                width:329px;
            }
        }
    }

    // Show Lohmann Brown above others
    #splide01-slide03 {
        z-index:6;
    }

    &.three-wide {
        max-width:1400px;
        margin:0 auto;

        .card-front, .card-back {
            width:300px;
            height: 430px;
            @include media-breakpoint-up(md) {
                width:412px;
                height: 590px;
            }
        }

        .card-back {
            display: flex;
            justify-content: center;
            flex-direction: column;
            background:#F8F8F8;
            .title {
                margin:5px 0 30px;
                @include media-breakpoint-up(md) {
                    font-size: 50px;
                    line-height: 50px;
                }
            }
        }

        .card-front {
            .title {
                position: absolute;
                bottom: 76px;
                width: 100%;
                left: 0px;
                text-align: center;
                color: white;
                font-size: 42px;
                line-height: 42px;
                @include media-breakpoint-up(md) {
                    font-size: 50px;
                    line-height: 50px;
                }
            }
        }

        .btn-flip-card {
            top:calc(100% - 60px);
        }

        .splide__slide {
            display: inline-flex;
        }
    }
}

.flip-cards-bg {
    position:absolute;
    width:100%;
    bottom:-160px;
    z-index:-1;
    display:none;
    @include media-breakpoint-up(md) {
        display:block;
    }
}
.flip-cards-bg-mobile {
    background: url(https://happyegg.com/assets/Page-Buildout/blue-swoosh.png);
    height: 330px;
    width: 100%;
    bottom: -60px;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    display:block;
    @include media-breakpoint-up(md) {
       display:none;
    }
}