.YolkElement {
    position:relative;
    width:100%;
    overflow:hidden;
    background: url(/_resources/themes/happyegg/images/yolk-element-bg.png) no-repeat;
    background-size:cover;
    z-index:1;
    color:$white;
    text-align:center;
    padding:30px 0 0;

    @include media-breakpoint-up(md) {
        padding:120px 0 0;
    }

    .content-wrap {
        position: relative;
        z-index:5;
        padding:60px 20px 0;
    }

    h2 {
        font-size:50px;
        @include media-breakpoint-up(md) {
            font-size:80px;
        }
    }

    p {
        font-size:24px;
        font-weight:600;
    }

    .yolk-comparison-scale {
        transform:translateY(20px);
        display:none;
        @include media-breakpoint-up(md) {
            display:block;
            margin:0 auto;
        }
    }

    .yolk-plate-mobile {
        margin-top:30px;
    }

    .mask {
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        z-index:10;
    }

    .mask-top {
        transform:scale(-1);
        top:-1px;
    }

    .bg-shape-left {
        position:absolute;
        top:0px;
        left:0px;
        // Hiding for performance
        // display:none;
    }
    .bg-shape-right {
        position:absolute;
        bottom:0px;
        right:0px;
        // Hiding for performance
        // display:none;
    }

}