.CTAElement, .find-store-small {
    margin-top: 20px;
    position:relative;
    z-index: 1;
    background-image: url(../../images/bg-orange-cta.png);
    background-size: cover;
    min-height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-x: clip;

    .content {
        color: #FFF;
        justify-content: center;


        h1, h2 {
            font-size: 50px;
            line-height:52px;
            font-style: normal;
            font-weight: 800;
            @include media-breakpoint-up(md) {
                font-size: 80px;
                line-height:88px;
            }

        }
        p {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            text-align: center;
        }

    }

    .white-curve {
        position: absolute;
        top: -35px;
        left: 0;
        width: 100%;
        opacity: 0;
        z-index: 1;
    }

    .locator-btn {
        border-radius: 36px;
        background: #FFF;
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.10);
        color: #FF5700;
        font-size: 14px;
        font-weight:700;
        display: flex;
        width: 240px;
        height: 60px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 0 auto;
        text-decoration: none;
    }

    .accent-egg {
        position:absolute;
        right:-50px;
        top: 80px;
        max-width: 200px;
        z-index: 3;
    }
    .accent-egg-brown {
        position: absolute;
        left: -50px;
        max-width: 200px;
    }

    .burger {
        position: absolute;
        left: -120px;
        top: 0px;
        // transform: rotate(-80deg);
        max-width: 450px;
    }

    .legbar {
        position: absolute;
        right: -30px;
        bottom: 5px;
        // transform: rotate(13deg);
        max-width: 330px;
        @include media-breakpoint-up(md) {
            bottom: 25px;
        }
    }

    .mobile-mask {
        top: -30px;
        left: 0px;
        position: absolute;
        z-index: 10;
        @include media-breakpoint-up(md) {
            display:none;
        }
    }
}

@include media-breakpoint-up(xl) {
    .CTAElement, .find-store-small {
        min-height: 850px;

        .legbar {
            position: absolute;
            right: 0px;
            bottom: 15px;
            // transform: rotate(13deg);
            max-width: none;
        }
        .burger {
            left: 30px;
            top: 0px;
            transform: rotate(-10deg);
            max-width: none;
        }
        .accent-egg {
            right: 60px;
            top: -10px;
            max-width: none;
        }
        .accent-egg-brown {
            left: 20px;
            bottom: -30px;
            max-width: none;
        }
        .white-curve {
            opacity: 1;
        }
    }

}

@include media-breakpoint-between(md, xl) {
    .CTAElement, .find-store-small{
        .legbar {
            max-width: 420px;
            bottom: 0;
        }
        .burger {
            max-width: none;
            top: -60px;
            left: -150px;
            transform: rotate(-80deg);
        }
        .accent-egg {
            right:60px;
            top: -10px;
            max-width: none;

        }
        .accent-egg-brown {
            left: 20px;
            bottom: -30px;
            max-width: none;
        }
        .white-curve {
            opacity: 0;
        }


    }
}

// For setting the curved bottom style in the CMS extra classes field
.StoreLocator {
    .mask {
        display: block !important;
        position:absolute;
        left:0px;
        bottom:-1px;
    }
    .accent-egg-brown {
        bottom:-50px;
        z-index:10;
    }
}

// Fix for extra large screens
.CTAElement:not(.StoreLocator) {
    @include media-breakpoint-up(lg) {
        padding-top:80px;
        top:30px;
    }
}
