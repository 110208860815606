.StatsElement {
    position: relative;
    z-index:5;

    h2 {color:$orange;}
}

.stats-wrap.Offset {
    padding-bottom:100px;

    .stat-item {
        border:none;
        @include media-breakpoint-up(md) {
            &:nth-child(2), &:nth-last-child(2) {
                transform:translateY(50px);
              }
            &:nth-child(3)  {
                transform:translateY(100px);
            }    
        }
    }
}

.stats-wrap {
    max-width:1180px;
    margin:0 auto;
    padding:20px;
    @include media-breakpoint-up(md) {
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
}

.stat-item {
    text-align:center;
    padding:25px 20px;
    &:last-child {border-right:none;}
    @include media-breakpoint-up(md) {
        width: calc(100% / 4);
        border-right: 1px solid $orange;
        padding:0 20px;
    }
}

.stat-value {
    color: $orange;
    font-size: 50px;
    font-family:'Qualy-Bold', sans-serif;
    line-height:50px;
    font-weight: 500;
    margin-bottom:10px;
}

.stat-label {
    font-size:20px;
    line-height:25px;
    font-weight:700;
    color:$orange;
    max-width: 220px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
        font-size:24px;
        line-height:29px;
    }
}