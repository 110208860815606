* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.GrowerApplyFooterElement {
    position: relative;
    margin-top: -10px;
    margin-bottom: -150px;
    background: url(../../images/blue-gradient-background.png) no-repeat;
    background-size: cover;
    min-height: 1300px;
    z-index: 2;
    color: white;
    display: flex;
    align-items: center;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
        min-height: 1600px;
    }
    @include media-breakpoint-up(md) {
        min-height: 1400px;
    }

    .apply-now-btn {
        bottom: 500px;
        left: 36%;
        z-index: 5;
        position: absolute;
        @include media-breakpoint-up(xl) {
            left: 50%;
            bottom: 430px;
        }
        @include media-breakpoint-up(lg) {
            left: 45%;
            bottom: 460px;
        }
    }



    .GrowerApplyFooterElement-background {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .grower-footer-text {
        position: absolute;
        width: 1500px;
    }

    #apply-img-1 {
        max-width: 200px;
        top: 150px;
        left: 30px;
        @include media-breakpoint-up(xl) {
            max-width: 350px;
            top: 250px;
            left: 30px;
        }
        @include media-breakpoint-up(md) {
            max-width: 250px;
        }
    }
    #apply-img-2 {
        max-width: 130px;
        top: 270px;
        right: 400px;
        display: none;
        @include media-breakpoint-up(xl) {
            max-width: 190px;
            top: 270px;
            right: 600px;
        }
        @include media-breakpoint-up(md) {
            max-width: 150px;
            top: 270px;
            right: 400px;
            display: unset;
        }

    }
    #apply-img-3 {
        max-width: 180px;
        right: 15px;
        top: 270px;
        @include media-breakpoint-up(xl) {
            max-width: 330px;
            right: 15px;
            top: 200px;
        }
        @include media-breakpoint-up(md) {
            max-width: 250px;
        }
    }
    #apply-img-4 {
        max-width: 320px;
        left: 20px;
        bottom: 110px;
        @include media-breakpoint-up(xl) {
            max-width: 470px;
            left: 20px;
            bottom: 190px;
        }
        @include media-breakpoint-up(md) {
            max-width: 380px;
        }
    }
    #apply-img-5 {
        max-width: 150px;
        bottom: 200px;
        left: 570px;
        display: none;
        @include media-breakpoint-up(xl) {
            max-width: 200px;
            bottom: 120px;
            left: 570px;
            display: unset;
        }
    }
    #apply-img-7 {
        max-width: 250px;
        right: 10px;
        bottom: 250px;
        display: none;
        @include media-breakpoint-up(xl) {
            max-width: 480px;
            right: 10px;
            bottom: 250px;
        }
        @include media-breakpoint-up(md) {
            max-width: 350px;
            display: unset;
        }
    }
}

