.info-background {
    width:100%;
    display:none;
    min-height:700px;
    max-height:980px;
    @include media-breakpoint-up(md) {
        display:block;
        min-height: 1000px;
    }
}


.infocard {
    position: relative;
    justify-content: center;
    align-items: center;
    justify-content: center;
    transform:translateY(-140px);
    background-image: url(/_resources/themes/happyegg/images/home-intro-background-mobile.png);
    background-size:100% 100%;
    background-repeat:no-repeat;
    padding:40px 0;
    z-index:5;
    overflow-x:clip;


    .desktop-bg {
        display:none;
        max-width: none;
        width: 100%;
        min-height: 800px;
        max-height:1000px;
        @include media-breakpoint-up(md) {
            display:block;
        }
    }

    @include media-breakpoint-up(md) {
        padding:0px;
        transform:translateY(-210px);
        background:transparent;
    }
    .info-content {
        width:100%;
        margin: 0 auto;
        z-index: 10;
        display: flex; /* Use flexbox to center vertically */
        flex-direction: column;
        @include media-breakpoint-up(md) {
            top: 50%;
        }

        .info-cards-title {
            text-align: center;
            width: 100%;
            padding: 10px;
            color: white;
            font-size: 45px;
            @include media-breakpoint-up(lg) {
                font-size: 80px;

            }
        }

        .info-scroll-container {
            // max-width: none;
            width:100%;
            padding:0 20px;
            margin: 0 auto;
            z-index: 10;
        @include media-breakpoint-up(md) {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            overflow: hidden;

        }

            .info-cards-section {
                    width: 100%;
                    min-height: 800px;
                    display: flex;
                    align-items: center;
                    animation-timing-function: linear;
                    animation: info-cards-section 16s linear infinite;
                    @include media-breakpoint-up(md) {
                        animation: info-cards-section 25s linear infinite;
                        min-height: 600px;
                    }

                    .info-card {
                        flex: 0 0 auto;
                        max-height: 600px;
                        max-width: 220px;
                        background-color: white;
                        border-radius: 20px;
                        box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
                        margin-right: 20px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        .quote-lg {
                            font-size: 24;
                        }
                        .info-icon {
                            max-width: 100px;
                            background: white;
                        }

                    }

                    .item-1 {
                        margin-top: 15px;
                    }
                    .item-2 {
                        margin-top: 60px;
                    }
                    .item-3 {
                        margin-top: -40px;
                    }
                    .item-4 {
                        margin-top: 15px;
                    }
                    .item-5 {
                        margin-top: 60px;
                    }
                    .item-6 {
                        margin-top: -40px;
                    }
                    .item-7 {
                        margin-top: 15px;
                    }
                    .item-8 {
                        margin-top: 60px;
                    }
                    .item-9 {
                        margin-top: -40px;
                    }

        @include media-breakpoint-up(lg) {

            .info-card {
                max-width: 540px;
                height: 360px;


                p {
                    font-size: 17px;
                }

            }

        }
    }


    }
    @keyframes info-cards-section{
        0% {
            transform: translateX(0,0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

}
}
