* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.FollowUsElement {
    position: relative;
    margin-top: -123px;
    margin-bottom: -150px;
    background: url(../../images/blue-gradient-bg.png) no-repeat;
    background-size: cover;
    z-index: 2;
    color: white;
    overflow: hidden;
    padding:100px 0 180px;

    .py-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .desktop-content {
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
        }

        .left-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @include media-breakpoint-up(xl) {
                transform:translate(150px, -50px);

            }

            h2 {
                font-size: 70px;
                line-height: 70px;
                font-weight: bold;
                color:$orange-light;
                @include media-breakpoint-up(xl) {
                    font-size: 80px;
                    line-height: 80px;
                }
            }
            p {
                font-size:24px;
                margin:30px 0 50px;
            }
        }

        h2 {
            font-size: 80px;
            font-weight: bold;
            max-width: 414px;
            color:$primary;
        }
        p {
            font-size:30px;
        }
    }

    .mobile-content {
        display: block;
        text-align:center;
        @include media-breakpoint-up(lg) {
            display: none;
        }

        h2 {
            color:$primary;
            font-size:50px;
            line-height:55px;
            max-width:350px;
            margin:0 auto;
        }
        p {
            font-size:24px;
            font-weight:700;
            margin:20px auto 30px;
        }
        img {
            max-width:400px;
            margin:40px auto;
        }
    }

    .top-row {
        transform:translateY(80px);
    }

    .FollowUs-background {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .FollowUs-content {
        display: flex;
        flex-direction: column;
        place-content: center;
        align-items: center;
        text-align: center;
        @include media-breakpoint-up(md) {
            align-items: start;
            text-align: left;
        }
        
        h1 {
            font-size: 60px;
            font-weight: bold;
            max-width: 394px;
            color: #F79320;
        }

        p {
            font-size: 20px;
            text-align: start;
        }

        .locator-btn {
            border-radius: 25px;
            background: var(--Sunny-Orange, #F7941D);
            text-transform: none;
            width: 148px;
        }
    }

    .add-happy-text {
        position: relative;
        z-index: 2;
    }

    .social-img {
        border-radius:20px;
        align-items: center;
    }

    .social-one {
        width:360px;
    }

    .social-two {
        width:246px;
    }
    .social-three {
        width:336px;
        transform:translateY(20px);
    }

    .social-four {
        width: 308px;
        transform:translateY(-30px);
    }
    
    .social-five {
        display:none;
        width:229px;
        position: absolute;
        left: -170px;
        top: -260px;
        @include media-breakpoint-up(lg) {
            display:block;
        }
    }
    .social-six {
        width: 258px;
    }
    .social-seven {
        width:529px;
    }


}