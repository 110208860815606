.PressReleasesPage {

    .page-heading {
        background-position: center;
    }

    .press-release-item {
        border-radius: 20px;
        background: rgba(27, 28, 71, 0.10);
        padding:20px;
        display:flex;
        flex-direction:column;
        min-height:250px;
        justify-content:space-between;
        color:$blue;
        transition: all 0.3s;
        box-shadow:0px 0px 0px $orange;
        text-decoration:none;
        @include media-breakpoint-up(md) {
            padding:30px;
            min-height:270px;
        }

        &:hover {
            background: var(--Light-Orange, rgba(255, 87, 0, 0.05));
            box-shadow:7px 7px 0px $orange;
            color: $orange;
        }

        .date {
            font-size:16px;
            margin:0px;
        }
    }

    .section-media-kit {
        position: relative;
        overflow-x:clip;
        padding:120px 0;
        background:rgba(255, 87, 0, 0.05);
        @include media-breakpoint-up(md) {
            padding:150px 0;
        }
    
        .mask {
            position:absolute;
            top:-1px;
            left:0px;
            width:100%;
            z-index:10;
            min-width:1200px;
        }

        .mask-btm {
            transform: scaleY(-1);
            bottom: -20px;
            top: unset;
        }
    }

    .media-kit-item {
        img {
            border-radius:20px;
        }
        .title {
            font-size:24px;
            font-weight:700;
            margin:10px 0 5px;
        }
        .subtitle {
            font-size:16px;
        }
    }

    .inquiry-tile {
        position:relative;

        &:before {
            content:'';
            position:absolute;
            bottom:0px;
            right:15px;
            z-index:-1;
            width:calc(100% - 30px);
            height:100%;
            border-radius:20px;
            background:$orange;
            transition:all 0.3s;
        }
        &:hover {
            &:before {
                right:0px;
                bottom:-10px;
            }
        }
        img {
            border-radius:20px;
        }
        .title {
            color: #FFF;
            font-size: 30px;
            line-height: 32px;
        }
        .subtitle {
            color: #FFF;
            font-size: 20px;
            line-height: 22px;
        }
    }

    .tile-content {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 0px;
        flex-direction: column;
        height: 100%;
        padding: 30px;

        @include media-breakpoint-up(md) {
            padding: 40px;
        }
    }
}
.press-release-content {
    img.left {
        float:left;
        padding-right:20px;
    }
    img.right {
        float:right;
        padding-left:20px;
    }
}
.press-release-heading {
    height:420px;
    @include media-breakpoint-up(md) {
        height:700px;
    }
}

.press-pagination {
    text-align:center;
    margin:40px auto;

    .inner {
        display:inline-block;
        width:calc(100% - 60px);
        max-width:900px;
    }

    .page-link {
        display:inline-block;
        font-weight:800;
        color:$blue;
        width:30px;
        height:30px;
        text-align:center;
        padding:3px;
        margin:0 3px;
    }
    .current-page {
        border-radius:50%;
        background:$sunny;
        color:#FFF;
        width:30px;
        height:30px;
        text-align:center;
        display:inline-block;
        margin:0 3px;
        padding:3px;
    }
}