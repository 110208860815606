.ProductsPage {

    // Custom overrides on elements for the product page
    .ArticleCarousel {
        position:relative;
        top:-10px;
        background:rgba(255,87,0,.05);
        padding:140px 0 100px;

        .mask {
            display: block;
            position:absolute;
            left:0px;
            top:0px;
            transform:scale(-1);
        }
    }
    .StoreLocator {
        position:relative;
        top:-180px;
        .mask {
            display: block !important;
            position:absolute;
            left:0px;
            bottom:-1px;
        }
        .accent-egg-brown {
            bottom:-50px;
            z-index:10;
        }
    }
    .StatsElement {
        position: relative;
        top:-100px;
    }

    .SocialMediaReviews {
        position:relative;
        top:-200px;
    }
}
.section-product {
    position:relative;
    overflow-x: clip;
    transform:translateY(-80px);
    color:$white;
    padding:140px 0;
    background-repeat:no-repeat;
    background-size: cover;

    @include media-breakpoint-up(md) {
        padding:190px 0 190px;
        background-size: 100% 100%;
    }

    .section-title {
        font-size:80px;
        line-height:80px;
    }

    .section-tagline {
        font-size:20px;
    }

    .review-snippet {
        opacity:1;
        transform:translateY(0px);
        .p-w-r .pr-category-snippet {
            margin:0px;
        }
    }

    .section-content {
        display:flex;
        padding:20px 0 0;

        .egg-info {
            width:120px;
            padding-right:15px;
            &:last-child {padding-right:0px;}
        }

        p {font-weight:700; font-size:14px;line-height:18px;padding:15px 0 5px;}
    }

    .btn {margin-right:30px;}
    .text-link {color:$white;text-decoration:none;font-size:14px;font-weight:700;}

    .p-row {
        position:relative;
        z-index:3;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        text-align:left;
        max-width: 1175px;
        margin: 0 auto;
        padding: 0 20px;
        flex-wrap:wrap;
        @include media-breakpoint-up(md) {
            flex-wrap:nowrap;
            justify-content:space-between;
        }

        &.reverse {
            flex-direction:row-reverse;
        }
    }

    .p-col {
        width:100%;
        @include media-breakpoint-up(md) {
            width:50%;
        }
    }
    .content-col {
        position:relative;
        z-index:5;
        max-width:370px;
        &.wide {
            max-width:492px;
        }
    }

    .image-col {
        text-align:center;
        max-width:400px;
        margin-bottom:20px;
        @include media-breakpoint-up(md) {
            text-align:left;
            max-width:none;
            margin:0px;
        }
    }

    .image-wrap {
        position: relative;
        display:inline-block;
        transform: scale(1.1);
        &:before {
            content: "";
            position: absolute;
            width: 80%;
            height: 65%;
            background: rgba(0,0,0,.2);
            filter: blur(20px);
            z-index: 0;
            top: calc(50% + 24px);
            left: 50%;
            transform: translate(-55%, -50%) rotate(-24deg);
            border-radius: 60px;            
        }
        img {
            position: relative;
            z-index: 1;
        }

    }

    .accent-image {
        position:absolute;
        z-index: 2;
    }
    .avocado-toast {
        @include media-breakpoint-up(md) {
            left: 50px;
            right:unset;
            bottom:-250px;
            width: 415px;
        }
    }
    .chilaquiles {
        right:-50px;
        bottom:-100px;
        z-index: 2;
        width: 220px;
        @include media-breakpoint-up(md) {
            bottom:-80px;
            width: 300px;
        }
    }
    .eggs-purgatory {
        right:-70px;
        bottom:-70px;
        z-index: 2;
        width: 250px;
        display:none;
        @include media-breakpoint-up(md) {
            top: -90px;
            left: 50%;
            right:unset;
            bottom:unset;
            transform: translateX(-50%);
            width: 350px;
            display:block;
        }   
    }
    .over-easy {
        display:none;
        bottom: 30px;
        right: 60px;
        width: 290px;
        z-index:0;
        @include media-breakpoint-up(md) {
            display:block;
        }
    }
    .grain-bowl {
        right:-20px;
        bottom:-90px;
        width: 180px;
        transform:scale(-1);
        @include media-breakpoint-up(md) {
            left:0px;
            right:unset;
            bottom:-200px;
            width: 290px;
            transform:scale(1);
        }
    }

    .mask {
        position:absolute;
        left:0px;
        bottom:0px;
        width:100%;
        height:100%;
        background-image: url(/_resources/themes/happyegg/images/product-section-mask.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position:left bottom;
        z-index:1;
    }

}

.section-heritage {
    transform:translateY(-65px);    
    background-image: url(/_resources/themes/happyegg/images/bg-blue.png);
    z-index:2;

    .avocado-toast {
        display:none;
        @include media-breakpoint-up(md) {
            display:block;
        }
    }
}
.section-vitamin-plus {
    transform:translateY(-130px);    
    background-image: url(/_resources/themes/happyegg/images/bg-orange.png);
}
.section-organic {
    transform:translateY(-197px);    
    background-image: url(/_resources/themes/happyegg/images/bg-green-2.png);
    @include media-breakpoint-up(md) {
        transform:translateY(-195px);    
    }

    .avocado-toast {
        display:block;
        right:-40px;
        top:-90px;
        z-index: 2;
        width: 250px;
        @include media-breakpoint-up(md) {
            display:none;
        }
    }

}
.section-free-range {
    transform:translateY(-130px);    
    background-image: url(/_resources/themes/happyegg/images/bg-orange-cta.png);
}
.heritage-over-easy {
    position:absolute;
    left:calc(50% - 100px);
}
