.ScrollingFAQs {
    min-height: 600px;
    position: relative;

    .scrolling-faq-header {
        font-size: 40px;
        font-weight: 800;

        @media (min-width: 992px) {
            font-size: 80px;
        }
    }

    .faq-boxed {
        border-radius: 20px;
        background: #EFEFEF;
        display: inline-block;
        margin: 10px;
        padding: 10px;
        font-size: 14px;
        min-width: 240px;

        @media (min-width: 992px) {
            padding: 25px;
            font-size: 24px;
        }
    }

    .faq-orange {
        color: #FF5700;
        margin: 10px;
        padding: 10px;
        font-size: 14px;
        min-width: 240px;

        @media (min-width: 992px) {
            padding: 25px;
            font-size: 24px;
        }
    }

    #FAQ-btn {
        padding: 15px 0;
        border-radius: 25px;
        background-color: #FF5700;
        color: #fff;
        width: 40%;
        z-index: 3;
        border: none;
        min-width: 150px;
    }

    .faq-row {
        animation-duration: 24s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: 1;

        @media (max-width: 767px) {
            //align-items: center;
            text-align: center;
        }
    }

    .faq-row:nth-child(odd) {
        animation-name: scroll-left;
    }

    .faq-row:nth-child(even) {
        animation-name: scroll-right;
    }

    @keyframes scroll-left {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes scroll-right {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }

    .header-row {
        animation: none;
        z-index: 2;
    }
}
