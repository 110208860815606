.coupon-popup {
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.75);
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:100;
    display:none;

    &.show {
        display:flex;
    }

    .text-opt-in {
        font-size: 11px;
        color: #fff;
        margin: 20px auto 0;
        font-weight: 100;
        // max-width: 360px;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    .hbspt-form {
        label {
            color:#fff;
        }
    
        .hs-form-field {
            margin-bottom:20px;
    
            label {
                width:100%;
                text-align:left;
                color:#fff;
            }
        }

        .hs-opt_in {
            text-align:center;
            margin:20px 0px 20px;
            padding:0px;
            ul {
                margin:0px;
                padding:0px;
                list-style-type:none;
            }
            li {
                margin:0px;
                padding:0px;    
            }
            label {
                color:#fff;
                font-size: 13px;
                padding-left: 20px;
                position: relative;
                a {
                    color: #fff;
                    text-decoration: underline;
                }
            }
            input {
                position: absolute;
                left: 0px;
                top: 2px;
            }
        }

        .hs-button {
            @extend .btn;
            @extend .btn-secondary;
            @extend .xwide;
        }
    
        .hs-error-msgs {
            color:#fff;
            text-align:left;
        }
    
        .submitted-message {
            color:#fff;
        }
    }
    
}

.coupon-inner {
    position:relative;
    padding:0 20px;
    opacity:0;
    transform:translateY(100px);
    transition:all .3s;
    width:100%;
    max-width:850px;
    background:url('../../images/bg-orange-cta.png') no-repeat center center;
    background-size:cover;
    text-align:center;
    padding:50px 30px;
    border-radius:20px;

    .heading {
        color:#fff;
        font-size:40px;
        font-weight:700;
        margin-bottom:10px;
    }
    .subheading {
        color:#fff;
        font-size:22px;
        margin-bottom:20px;
    }

    .hbspt-form {
        width:100%;
        max-width:500px;
        margin:0 auto;
    }

    &.show {
        opacity:1;
        transform:translateY(0);
    }
}

.coupon-image {
    display:none;
    box-shadow:0 0 20px rgba(0,0,0,0.25);
    border-radius:38px;
    @include media-breakpoint-up(md) {
        display:block;
    }
}
.coupon-image-mobile {
    display:block;
    box-shadow:0 0 20px rgba(0,0,0,0.25);
    border-radius:38px;
    max-width:440px;
    @include media-breakpoint-up(md) {
        display:none;
    }

}

#btn-open-coupon {
    cursor:pointer;
}

#btn-close-coupon-popup {
    position:absolute;
    top:15px;
    right:3px;
    z-index:101;
    color:#000;
    font-size:30px;
    text-decoration:none;
    background:#fff;
    border-radius:50%;
    width:33px;
    height:33px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    @include media-breakpoint-up(md) {
        top:-15px;
        right:-15px;
    }

    &:hover {
        color:#000;
    }
}

