.RecipesPage {

    .cat-nav {
        padding:10px 0 50px;

        .btn {
            margin:0 4px;
        }
    }

    // .grid-item { width: 25%; }
    .rcol.first { 
        @include media-breakpoint-up(md) {
            width:100%;
        }
        @include media-breakpoint-up(lg) {
            width: 50%; 
            padding-top:0px !important;
        }
    }
    .rcol-1, .rcol-4 {
        @include media-breakpoint-up(lg) {
            padding-top:70px;
        }
    }
    .rcol:nth-child(2) {
        @include media-breakpoint-up(lg) {
            padding-top:70px;
        }
    }
    .rcol:nth-child(3) {
        @include media-breakpoint-up(lg) {
            padding-top:0px;
        }
    }
    

    .article-tile {
        a {
            display:block;
            text-decoration:none;
            padding-top:10px;
        }

        img {
            border-radius:20px;
            box-shadow:0px 0px 0px $orange;
            transition: all .2s;
        }

        .title {
            text-decoration: none;
            color:$dark-blue;
            padding:15px 0;
        }

        &:hover {
            .title {
                color:$orange;
            }

            img {
                transform:translate(-10px,-10px);
                box-shadow:10px 10px 0px $orange;
            }
        }

    }


    .pagination {
        position: relative;
        justify-content:center;
        text-align:center;
        padding:0 20px;
        margin:50px 0 100px;

        .active {
            background-color:$orange;
            color:$white;
            width:30px;
            height:30px;
            border-radius:50%;
            padding-top: 5px;
            font-size: 14px;
            margin:0 2px;
        }
        .link {
            width:30px;
            height:30px;
            color:$blue;
            text-decoration:none;
            padding-top: 5px;
            font-size: 14px;
            margin:0 2px;
        }

        .next {
            position:absolute;
            right:20px;
            top:0px;
        }
    }

}