.farm-map {
    position:relative;
    background-image: url(../../images/farm-map-background.png);
    background-size:cover;
    background-repeat:no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:840px;
    overflow: hidden;
    margin:40px 0;

    @include media-breakpoint-up(md) {
        min-height: 1000px;
    }
    .image-wrap {
        position: relative;
        margin:80px 0;
    }

    .map-image {
        width:1440px;
        max-width:none;
        transform: translateX(-12%);
        @include media-breakpoint-up(md) {
            transform: translateX(0px);
        }
    }

    .farm-pin {
        display:none;
        position:absolute;
    }

    .info-panel {
        display:none;
        position: absolute;
        z-index:5;
        top:50%;
        left:30px;
        transform:translateY(-50%);
        border-radius: 20px;
        background: #FFF;
        padding:40px;
        max-width:350px;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(md) {
            padding:40px;
            display:flex;
            left:30px;
        }
        @include media-breakpoint-up(lg) {
            padding:40px;
            max-width:550px;
            left:60px;
        }
        @include media-breakpoint-up(xl) {
            padding:65px;
            left:100px;
        }

        svg {
            margin-bottom:30px;
            max-width:350px;
            padding:0 40px;
            @include media-breakpoint-up(lg) {
                margin-bottom:50px;
                max-width:450px;
            }
        }

        p {
            font-size:18px;
            color:$navy-blue;
            @include media-breakpoint-up(lg) {
                font-size:20px;
            }
        }
    }

    .mask {
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        z-index:10;
    }

    .mask-top {
        transform:scale(-1);
        top:-1px;
    }

    // .map-pin {
    //     &.active {
    //         transform:scale(1.1);
    //     }
    // }

}

.map-tooltip {
    display:none;
    z-index:25;
    position:absolute;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.25);
    padding:20px;
    width:440px;
    transform:translateX(-50%);

    &:before {
        content: "";
        background: #fff;
        position: absolute;
        bottom: -17px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
        width: 20px;
        height: 20px;
    }
}
