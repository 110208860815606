.CareersPage {
    #header:not(.menu-open) {
        .bar {background:$blue;}
        .logo-blue {display:block;}
        .logo-white {display:none;}
    }
    #header.solid {
        .bar {background:$white;}
        .logo-blue {display:none;}
        .logo-white {display:block;}
    }
    overflow-x: hidden;

    .careers-apply {
        position: absolute;
        width: 100%;
        text-align: center;
        padding: 40px;
    }
    
    .success-message {
        position: absolute;
        top: 30%;
        font-size: 20px;
        left: 0px;
        padding: 20px;
    }
}

#career-form {
    margin-top:70px;
    margin-bottom:70px;
    .formContainer {

        position: relative;
        display: grid;
        max-width: 555px;
        border-radius: 20px;
        margin: 0 auto;
        column-gap: 30px;
        row-gap: 10px;
        grid-template-columns: 1fr 1fr;
        box-shadow: 0px 40px 120px 0px rgba(27, 28, 71, 0.20);
        padding: 25px;
        z-index: 2;
        padding-top:160px;

        @include media-breakpoint-up(md) {
            padding: 50px;
            padding-top:170px;
            row-gap: 20px;
        }

        h3 {
            grid-column: 1 / 3;
            color:#FF5700;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        & > input {
            border: none;
            background-color: #EFEFEF;
            padding: 16px 0 15px 24px;
            width: 100%;
            box-sizing: border-box;
            line-height: 20px;
            font-size: 16px;
            font-family: filson-pro, sans-serif;
            border-radius: 8px;
            color: #0e255a;
            transition: box-shadow 0.2s, transform .2s;

            @media screen and (max-width: 614px) {
                grid-column: 1 / 3;
            }

            &::placeholder {
                color: rgba(14, 37, 90, 0.4);
            }

            &:focus {
                outline: none;
                transform: translate(-6px, -6px);
                box-shadow: 6px 6px 0 0 #EFEFEF;

            }
        }

        & > input:not([name="newsletter"]), & > textarea {
            grid-column: 1 / span 2; // This will make all inputs (except newsletter) and textarea span two columns
        }

        .topics {
            grid-column: 1 / 3;
            display: flex;
            flex-wrap: wrap;

            .label {
                font-size: 16px;
                line-height: 20px;
                flex-basis: 100%;
                color: #0e255a;
                margin: 0 0 14px 0;
            }

            label {
                display: inline-flex;
                position: relative;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;

                input {
                    appearance: none;
                    position: absolute;
                    margin: 0;

                    &:focus + span {
                        outline: 2px solid EFEFEF;
                        background-color: #FF5700;
                    }

                    &:checked + span {
                        background-color: #FF5700;
                        color: #fff;
                    }
                }

                span {
                    display: inline-block;
                    user-select: none;
                    padding: 10px 18px 10px 17px;
                    font-size: 12px;
                    line-height: 20px;
                    color: #1B1C47;
                    border: solid 1px #EFEFEF;
                    background-color: #EFEFEF;
                    border-radius: 25px;
                    transition: background-color 0.1s, color 0.1s;

                    @include media-breakpoint-up(md) {
                        font-size: 16px;

                        padding: 15px 23px 15px 23px;
                        line-height: 20px;
                    }
                }
            }
        }

        .upload {
            grid-column: 1 / 3;

            .label {
                font-size: 16px;
                line-height: 20px;
                flex-basis: 100%;
                color: #0e255a;
                margin: 0 0 14px 0;
            }

            label {
                display: block;
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                width: 100%;
                box-sizing: border-box;
                padding: 19px 0 15px 0;
                color: #FF5700;
                border-radius: 8px;
                border: solid 1px #FF5700;
                transition: background-color 0.2s, color 0.2s;
                cursor: pointer;

                &:hover {
                    background-color: #FF5700;
                    color: #fff;
                }
            }

            input {
                display: none;
            }

            .file {
                text-align: center;
                margin: 14px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: rgb(14, 37, 90, 0.45);
            }
        }

        textarea, select {
            grid-column: 1 / 3;
            font-family: filson-pro, sans-serif;
            padding: 20px 24px;
            line-height: 20px;
            font-size: 16px;
            color: #0e255a;
            border-radius: 8px;
            resize: vertical;
            border: none;
            background-color: #EFEFEF;

            &:focus {
                outline: 2px solid #0e255a;
            }

            &::placeholder {
                font-family: filson-pro, sans-serif;
                line-height: 20px;
                font-size: 16px;
                color: rgba(14, 37, 90, 0.4);
            }
        }

        .newsletter {
            grid-column: 1 / 3;
            display: flex;
            align-items: center;


            input {
                appearance: none;
                display: inline-block;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                margin: 0;


                &:not(:checked) {
                    border: 2px solid #FF5700;
                    background: transparent;
                }

                &:checked {
                    background: #FF5700;
                    border: none;

                    &::after {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3ECheckmark%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='square' stroke-miterlimit='10' stroke-width='44' d='M416 128L192 384l-96-96'/%3E%3C/svg%3E");
                        content: "";
                    }
                }
            }

            span {
                font-size: 12px;
                margin-left: 12px;
                line-height: 1em;
                color: #0e255a;
                @include media-breakpoint-up(md) {
                    font-size: 16px;
                }
            }
        }

        .recaptcha {
            display: flex;
            grid-column: 1 / 3;
            justify-content: center;

            span {
                position: relative;
            }
        }

        .contact-submit {
            grid-column: 1 / 3;
            //@include button.button();
            margin: 0 auto;
            flex-direction: column;
            align-items: center;
            border: none;
            width:140px;
        }

        .hidden {
            display: none;
        }
    }


	.error {
		grid-column: 1 / 3;
		text-align: center;
		font-size: 20px;
		line-height: 24px;
		margin: 0;
		color: red;
	}

}


.success {
    position: relative;
    text-align: center;
    pointer-events: none;
    display: none;
    z-index: 1;
    background: #FFF;
    box-shadow: 0px 40px 120px 0px rgba(27, 28, 71, 0.20);
    background-image: url('../../images/Vector-success.svg');
    background-repeat: no-repeat;
    max-width: none;
    height: 647px;
    flex-shrink: 0;
    border-radius: 20px;
    margin: 10px auto;
    column-gap: 30px;
    row-gap: 10px;
    grid-template-columns: 1fr 1fr;
    padding: 18px;
    z-index: 2;
    @include media-breakpoint-up(sm) {
        max-width: 555px;
    }



    .success-image {
        position: relative;
        //opacity: 1;
        transition: opacity .3s .3s;
        margin-top: 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        &::before {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #faf1e0;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-image: url(/_resources/themes/happy-egg/assets/blob-2.svg);
            content: "";
        }

        svg {
            position: relative;
            height: 100%;
            width: 100%;
        }
        .success-heading {
            margin: 14px 0;
            font-size: 50px;
            font-weight: 800;
            line-height: normal;
            color: #FF5700;
            //opacity: 0;
            transform: translateY(40px);
            transition: transform .5s .3s, opacity .5s .3s;

            @media screen and (max-width: 500px) {
                font-size: 16vw;
            }
        }
        .success-message {
            font-family: filson-pro, sans-serif;
            margin: 0;
            font-size: 24px;
            line-height: 35ox;
            color: #0e255a;
            //opacity: 0;
            transform: translateY(40px);
            transition: transform .7s .3s, opacity .7s .3s;
        }
        #FAQ-btn {
            display: flex;
            margin-top: 80px;
            padding: 14px 15px 10px 15px;
            border-radius: 25px;
            background-color: #FF5700;
            color: #fff;
            width: 200px;
            transform: translateY(40px);
            transition: transform .5s .3s, opacity .5s .3s;
            border: none;
            justify-content: center;
            text-decoration: none;
        }
        .accent-egg-success {
            position: absolute;
            bottom: 20px;
            left: -35px;
            max-width: 80px;

            @include media-breakpoint-up(md) {
                max-width: 140px;
                bottom: -50px;
                left: -25px;
            }
        }
        .falling-egg-success {
            position: absolute;
            top: 0px;
            right: -50px;
            transform: rotate(5.607deg);
            max-width: 100px;
            @include media-breakpoint-up(md) {
                max-width: none;
            }

        }

    }


}

