.WriteReviewPage {

    .pr-control-label {
        float:none;
    }

    #pr-write {
        margin:0 auto;
        max-width:850px;
        padding:20px;

        .p-w-r {
            font-family: 'Poppins';

            .review-preview {
                .pr-star-v4 {
                    width:24px;
                    height:24px;
                    background-image:url("data:image/svg+xml;base64,ICAgICAgICAgICAgPHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS4wOSA4LjI2TDEyIDJMOC45MSA4LjI2TDIgOS4yN0w3IDE0LjE0TDUuODIgMjEuMDJMMTIgMTcuNzdMMTguMTggMjEuMDJMMTcgMTQuMTRMMjIgOS4yN0wxNS4wOSA4LjI2WiIgZmlsbD0iI0ZGNTcwMCIgc3Ryb2tlPSIjRkY1NzAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogICAgICAgICAgICA8L3N2Zz4K");
                }
                .pr-star-v4-0-filled {
                    width:0px;
                }
                .pr-star-v4-25-filled {
                    width:6px;
                }
                .pr-star-v4-50-filled {
                    width:12px;
                }
                .pr-star-v4-75-filled {
                    width:18px;
                }
            }
        
        
            .pr-btn-review {
                @extend .btn;
                background: $orange;
                font-family: 'Poppins';
                font-size: 14px !important;
        
                &:hover {
                    background: $orange;
                    color:#fff;
                }
        
            }

            .pr-underline, .pr-clear-all-radios {
                color:$orange !important;
            }
            .pr-control-label span {
                color:$blue;
            }

            input::placeholder, textarea::placeholder {
                font-family: filson-pro, sans-serif;
                line-height: 20px;
                font-size: 16px;
                color: rgba(14, 37, 90, 0.4);
            }

            .pr-btn.pr-btn-default.active {
                background: $orange;
                color:#fff;
            }

            .pr-form-group:not(.pr-rating-form-group, .pr-quality-form-group, .pr-value-form-group, .pr-taste-form-group, .pr-bottomline-form-group) {
                position:relative;

                .pr-control-label {
                    position: absolute;
                    left: 18px;
                    top: 10px;
                    color: $blue;
                    z-index:4;
                }
            }

            input[type=text], input[type=email], input[type=tel], input[type=number], input[type=date], textarea {
                border: none;
                background-color: #F8F8F8;
                padding:33px 0 11px 18px !important;
                width: 100%;
                box-sizing: border-box;
                line-height: 20px;
                font-size: 16px;
                font-family: filson-pro, sans-serif;
                border-radius: 8px;
                color: #0e255a;
                transition: box-shadow 0.2s, transform .2s;
                box-shadow:none;
                height:auto !important;
            }
        
            
        }
    }

}

// Power Reviews Overrides
#pr-reviewsnippet {
    .p-w-r .pr-snippet .pr-snippet-stars-png {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
    }

    .pr-snippet-stars-reco-reco {
        display:none;
    }

    .p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
        margin:0 10px;
    }

    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
        float:none;
        position:relative;
        display:flex;
        justify-content:center;
        padding:80px 0;
        margin-bottom:30px;
    }

    .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
        display: inline-block;
        font-size: 50px;
        line-height: 55px;
        color: #1b1c47;
        margin: 0px;
        border: none;
        font-family: 'Poppins';
        font-weight: 500;
    }

    .p-w-r .pr-snippet-read-and-write a.pr-snippet-review-count {
        display: inline-block;
        font-size: 20px !important;
        line-height: 25px;
        color: #1b1c47;
        margin: 0px;
        font-family: 'Poppins';
    }

    .p-w-r {
        max-width:800px;
        margin:0 auto;
        padding:20px;
        .pr-star-v4 {
            width:24px;
            height:24px;
            background-image:url("data:image/svg+xml;base64,ICAgICAgICAgICAgPHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS4wOSA4LjI2TDEyIDJMOC45MSA4LjI2TDIgOS4yN0w3IDE0LjE0TDUuODIgMjEuMDJMMTIgMTcuNzdMMTguMTggMjEuMDJMMTcgMTQuMTRMMjIgOS4yN0wxNS4wOSA4LjI2WiIgZmlsbD0iI0ZGNTcwMCIgc3Ryb2tlPSIjRkY1NzAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogICAgICAgICAgICA8L3N2Zz4K");
        }
        .pr-star-v4-25-filled {
            width:6px;
        }
        .pr-star-v4-50-filled {
            width:12px;
        }
        .pr-star-v4-75-filled {
            width:18px;
        }

        .pr-snippet-write-review-link  {
            @extend .btn;
            position: absolute;
            bottom: 0px;
            left: 50%;
            background: $orange;
            transform: translate(-50%, 0px);
            font-size: 14px !important;

            &:hover {
                background: $orange;
                color:#fff;
            }
        }
    }

}
#pr-review-snapshot, #pr-rd-main-header {
    display:none;
}
#pr-reviewdisplay {
    .p-w-r {
        max-width:800px;
        margin:0 auto;
        padding:20px;
        font-family: 'Poppins';

        .pr-rd-side-content-block.pr-rd-right {
            float:none;
            position: relative;
            width:100%;
            padding:0px;
            right:0px;

            .pr-rd-reviewer-details {
                display: flex;
            }
            .pr-rd-details {
                padding-right:15px;
            }
        }
    }
    .pr-rd-footer {
        display:none;
    }
    .pr-star-v4 {
        width:24px;
        height:24px;
        background-image:url("data:image/svg+xml;base64,ICAgICAgICAgICAgPHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS4wOSA4LjI2TDEyIDJMOC45MSA4LjI2TDIgOS4yN0w3IDE0LjE0TDUuODIgMjEuMDJMMTIgMTcuNzdMMTguMTggMjEuMDJMMTcgMTQuMTRMMjIgOS4yN0wxNS4wOSA4LjI2WiIgZmlsbD0iI0ZGNTcwMCIgc3Ryb2tlPSIjRkY1NzAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogICAgICAgICAgICA8L3N2Zz4K");
    }
    .pr-star-v4-0-filled {
        width:0px;
    }
    .pr-star-v4-25-filled {
        width:6px;
    }
    .pr-star-v4-50-filled {
        width:12px;
    }
    .pr-star-v4-75-filled {
        width:18px;
    }

    .pr-review {
        border-bottom: 1px solid #1b1c47;
        padding-bottom: 20px;
        margin-bottom: 30px;
    }

    .pr-rd-review-headline {
        font-size: 30px;
        margin: 10px 0 0px;
        font-family:'Qualy-Bold';
        color:$blue;
        float:none;
    }
    .pr-snippet-rating-decimal {
        display:none;
    }

    .pr-review-display.pr-rd-display-desktop .pr-rd-content-block {
        width:100%;
        p, span {
            font-size:16px;
            line-height:24px;
            color:$blue;    
        }
    }
    .pr-rd-pagination-btn, .pr-rd-to-top {
        color:$orange;
        padding-top:2px;
        span {
            color:$orange !important;
        }
    }
    .pr-rd-star-rating {
        float:none;
    }

}
