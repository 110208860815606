.element.image {
	display: flex;
	max-width: 960px;
	height: auto;
	margin: 80px auto;
	box-sizing: content-box;
	padding: 0 40px;
    justify-content: center;

	@media screen and (max-width: 800px) {
		padding: 0 20px;
	}

	img {
		width: 100%;
		height: auto;
		border-radius: 10px;
	}

    &.full-width {
        max-width:1160px;
    }
}