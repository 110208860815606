.HubSpotEmbed {

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transform:translateY(-50px);
    background-image: url(/_resources/themes/happyegg/images/home-intro-background-mobile.png);
    background-size:100% 100%;
    background-repeat:no-repeat;
    padding:90px 0;
    z-index:10;
    @include media-breakpoint-up(md) {
        padding:0px;
        transform:translateY(-85px);
        background:transparent;
    }
    @include media-breakpoint-up(lg) {
        transform:translateY(-90px);
    }
    @include media-breakpoint-up(xl) {
        transform:translateY(-100px);
    }
    @include media-breakpoint-up(xxl) {
        transform:translateY(-124px);
    }

    .hubspot-content {
        max-width: 1200px;
        width:100%;
        padding:0 20px;
        margin: 0 auto;
        z-index: 10;
        text-align: center;
        color: white;
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .desktop-bg {
        display:none;
        max-width: none;
        width: 100%;
        min-height: 800px;
        max-height:1000px;
        @include media-breakpoint-up(md) {
            display:block;
        }
    }


    .hbspt-form {
        max-width:750px;
        margin:0 auto;
        label {
            color:#fff;
        }
    
        .hs-form-field {
            margin-bottom:20px;
    
            label {
                width:100%;
                text-align:left;
                color:#fff;
            }
        }

        .actions {
            text-align:center;
        }
    
        .hs-button {
            @extend .btn;
            @extend .btn-secondary;
            @extend .xwide;
        }
    
        .hs-error-msgs {
            color:#fff;
            text-align:left;
        }
    
        .submitted-message {
            color:#fff;
        }
    }

    .text-opt-in {
        color:#fff;
        font-size:12px;
        text-align:center;
        margin-top:20px;

        a {
            color:#fff;
            text-decoration:underline;
        }
    }

}