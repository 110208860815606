#menu-container {
    position:fixed;
    background:$blue url(/_resources/themes/happyegg/images/navmenu-background.png);
    background-size:cover;
    padding-top:140px;
    padding-bottom:40px;
    display:none;
    height:0px;
    transition:all .3s;
    z-index:25;
    color:$white;
    overflow-y:scroll;
    overflow-x:clip;
    flex-direction:column;
    justify-content:flex-start;
    height:100vh;

    @include media-breakpoint-up(md) {
        justify-content:space-between;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom:100px;
    }

    &.open {
        display:flex;
        height:100vh;
    }

    ul {
        list-style-type:none;
        margin:0px;
        padding:0px;
    }

    .nav-primary a {
        display:block;
        color: #FFF;
        font-family:'Qualy-Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-decoration:none;
        padding:5px 0;
        transition:color .2s;

        &:hover {color:#FF8F14;}

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            padding:10px 0;
        }

    }

    .nav-secondary {
        @include media-breakpoint-down(sm) {
            margin-top:30px;
            margin-bottom:30px;
            }
        a {
            display:block;
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration:none;
            padding:10px 0;
            transition:color .2s;

            &:hover {color:#FF8F14;}
        }
    }

    .social-links {
        a {padding-right:10px;}
        svg {
            padding:0 2px;
            fill:$white;
            height:20px;
        }
    }

    .mobile-product-row {
        display:flex;
        @include media-breakpoint-up(md) {
            display:none;
        }
    }
    .top-shape {
        display:block;
        width:100%;
        position:absolute;
        z-index:-1;
        top:0px;
        left:0px;
        @include media-breakpoint-up(md) {
            display:none;
        }
    }

    .nav-products-splide {
        padding:20px 0;
    }

    .splide__slide {
        transform:scale(.75) translateY(-10px);
        opacity:.5;
        transition:all .4s;

        &.is-active {
            transform:scale(1);
            opacity:1;

            .title {
                opacity:1;
            }
        }

        .title {
            font-size:24px;
            font-weight:700;
            text-align:center;
            opacity:0;
        }
    }
    .slide-image-wrap {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: 90%;
            height: 70%;
            background: rgba(0,0,0,.5);
            filter: blur(20px);
            z-index: 2;
            top: calc(50% + 14px);
            left: 50%;
            transform: translate(-50%, -50%);
        }
        img {
            position: relative;
            z-index:4;
        }
    }
    .splide__pagination {
        display:flex;
    }
    .splide__pagination__page {
        background:$white;
        opacity:1;
        &.is-active {
            background:$orange;
        }
    }

    .desktop-product-row {
        display:none;
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: nowrap;
            max-width: 1200px;
            align-self: flex-end;
            width: 100%;
            justify-content: space-around;
        }
    }

    .product-item {
        padding:0 10px;
        flex-shrink:1;
        max-width:320px;
    }

    .product-link {
        position: relative;
        display: flex;
        flex-direction:row;
        flex: 1;
        height: 100%;
        align-items: flex-end;
        padding-bottom:30px;
        
        .image-wrap {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                width: 90%;
                height: 70%;
                background: rgba(0,0,0,.5);
                filter: blur(20px);
                z-index: -1;
                top: calc(50% + 14px);
                left: 50%;
                transform: translate(-50%, -50%);
            }
    
        }

        .title {
            position:absolute;
            bottom:-10px;
            left:0px;
            width:100%;
            text-align: center;
            opacity:0;
            margin:0px;
            color:$white;
            font-size:24px;
            text-decoration:none;
            transition:all .1s;
        }

        &:hover {
            .carton-image-open {
                display:block;
            }
            .carton-image {
                display:none;
            }
            .title {
                opacity:1;
            }
        }
    }

    .carton-image-open {
        display:none;
        transform:rotate(-10deg) translateY(-15px);
    }
}