
.GrowerPage {
    overflow-x: hidden;
    .grower-subtitle {
        color: white;
    }
    #header {
        #btn-icon-menu, .btn-store-locator {
            display:none;
        }
        .logo {
            transform:none;
            left:20px;
            top:3px;
        }

        #btn-apply {
            position:absolute;
            right:20px;
            top:32px;
        }
    }

    .success {
        width:100%;
    }
}

