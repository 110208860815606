.LargeQuoteElement {
    margin-top: -110px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 60% auto;
    padding-bottom: 150px;
    @include media-breakpoint-up(sm) {
        background-image: url('../../images/vector-4.webp');
        min-height: 900px;
    }
    @include media-breakpoint-up(xxl) {
        background-size: 55% auto;
    }

        .quote-box {

                .quote-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-bottom: 0px;
                    @include media-breakpoint-up(xl) {
                        padding-bottom: 100px;
                    }
                    }
                .quote-img {
                    display: flex;
                    margin-top: -350px;
                    @include media-breakpoint-up(sm) {
                        margin-top: unset;
                    }
                }


        }
    }

