.mosaicgrid {
    .blog-links-section {
        margin-top: 50px;
        margin-bottom: 200px;
        @include media-breakpoint-up(lg) {
            .row {
                .mosaic-item-1 {
                    max-width: 550px;
                    min-width: 500px;
                    margin-top: -30px
                }
                .mosaic-item-2 {
                    padding-top: 40px;
                    max-width: 320px;
                }
                .mosaic-item-3 {
                    max-width: 320px;
                }
                .mosaic-item-4 {
                    margin-top: -50px;
                    max-width: 320px;
                }
                .mosaic-item-5 {
                    padding-top: 70px;
                    max-width: 320px;
                }
                .mosaic-item-6 {
                    min-width: 500px;
                    max-width: 550px;
                    padding-top: 30px;
                    clear: both;
                }
            }

        }

    }

}

