.element.content {
    
    h1,h2,h3,h4 {
        color:$orange;
    }

    .content-inner {
        position:relative;
        z-index:7;
        padding:0 20px;
        margin:0 auto;
        width:100%;
        max-width:900px;
    }

}