.CouponPage {

    .hero-coupon {
        width:250px;
        margin:0 auto;
        display:block;
        @include media-breakpoint-up(md) {
            width:365px;
        }
    }

    .page-heading {
        h1 {
            font-size: 60px;
            line-height: 62px;
            @include media-breakpoint-up(md) {
                font-size: 80px;
                line-height: 88px;
            }
        }
        img:not(.hero-coupon) {
            width:175px;
            margin:10px auto;
            @include media-breakpoint-up(md) {
                width:250px;
                margin:0 auto;
            }
        }
    }

    .content-wrap {
        position: relative;

        .icon-egg {
            display: block;
            width:60px;
            margin:30px auto 0;
            @include media-breakpoint-up(md) {
                position: absolute;
                right: 0px;
                top: 50%;
                width: 75px;
                transform: translateY(-20%);
            }
        }
    }

    .intro-background {
        width:100%;
        display:none;
        min-height:550px;
        max-height:980px;
        @include media-breakpoint-up(md) {
            display:block;
        }
    }

    .intro-content-mobile {
        position: relative;
        margin:40px auto 0;
        display:block;
        @include media-breakpoint-up(md) {
            display:none;
        }

        .dotted-line-wrap-mobile {
            position: absolute;
            left: 0px;
            top: 0px;
            overflow-y: clip;
            height: 0px;
            width: 100%;
            transition: all 1.5s ease-out;
            &.show {
                height:100%;
            }
        }
        .dotted-line-mobile {
            position: absolute;
            width: auto;
            max-height: none;
            left: 50%;
            top: 0px;
            transform: translateX(-50%);
        }

    }
    .intro-content-desktop {
        margin:30px 0 0;
        display:none;
        position:relative;
        @include media-breakpoint-up(md) {
            display:block;
        }
        @include media-breakpoint-up(lg) {
            margin:80px 0 0;
        }

        .dotted-line-wrap {
            position: absolute;
            left: 0px;
            top: 0px;
            overflow-x: clip;
            height: 100%;
            width:0px;
            transition:all 1.5s ease-out;
            &.show {
                width:100%;
            }
        }
        .dotted-line {
            position: absolute;
            height: 100%;
            max-width: none;
            left: 0px;
            top: 0px;
        }
    }

    .home-intro {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        transform:translateY(-70px);
        background-image: url(/_resources/themes/happyegg/images/home-intro-background-mobile.png);
        background-size:100% 100%;
        background-repeat:no-repeat;
        padding:90px 0;
        z-index:5;
        @include media-breakpoint-up(md) {
            padding:0px;
            transform:translateY(-60px);
            background:transparent;
        }
        @include media-breakpoint-up(lg) {
            transform:translateY(-70px);
        }
        @include media-breakpoint-up(xl) {
            transform:translateY(-80px);
        }
        @include media-breakpoint-up(xxl) {
            transform:translateY(-90px);
        }

        .content-row {
            display:flex;
            flex-direction:row;
            justify-content:space-evenly;
            align-items:center;
            display:none;
        }
        .content-cell {
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;


            p {font-size:24px;line-height:30px;}
        }
        .icon-wrap {
            display:flex;
            justify-content:center;
            align-items:center;
            width:163px;
            height:163px;
            border-radius:50%;
            background-color:$white;
        }

        .desktop-bg {
            display:none;
            max-width: none;
            width: 100%;
            min-height: 800px;
            max-height:1000px;
            @include media-breakpoint-up(md) {
                display:block;
            }
        }
    }

    .intro-content {
        max-width: 1200px;
        width:100%;
        padding:0 20px;
        margin: 0 auto;
        z-index: 10;
        text-align: center;
        color: white;
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .landing-intro {
        .mobile-heading-break {
            display:block;
            @include media-breakpoint-up(md) {
                display:none;
            }
        }
        .subheading {
            font-size: 20px;
            line-height: 32px;
            margin:20px auto 30px;
            font-weight:800;
            max-width:320px;
            @include media-breakpoint-up(md) {
                font-size: 28px;
                line-height: 34px;
                max-width: 100%;
            }

            span {
                font-size:28px;
                font-size:32px;
                @include media-breakpoint-up(md) {
                    font-size:41px;
                    font-size:45px;
                }
            }
        }

        .cartons-mobile {
            display:block;
            margin-bottom:20px;
            @include media-breakpoint-up(md) {
                display:none;
            }
        }
        .cartons-desktop {
            display:none;
            @include media-breakpoint-up(md) {
                display:block;
            }
        }
    }

}