@font-face {
    font-family: 'Qualy-Bold';
    src: url('../../fonts/QualyNeue-Bold.otf') format('opentype');
}

@import "./partials/settings.scss";

@import "bootstrap";
@import "./partials/overrides.scss";

@import "./partials/typography.scss";
@import "./partials/buttons.scss";
@import "./partials/header.scss";
@import "./partials/footer.scss";
@import "./partials/forms.scss";
@import "./partials/pageheading.scss";
@import "./partials/navmenu.scss";
@import "./partials/coupon.scss";

@import "./pages/home.scss";
@import "./pages/faqpage.scss";
@import "./pages/contactpage.scss";
@import "./pages/careerspage.scss";
@import "./pages/choosehappypage.scss";
@import "./pages/article.scss";
@import "./pages/productspage.scss";
@import "./pages/productdetail.scss";
@import "./pages/recipespage.scss";
@import "./pages/locator.scss";
@import "./pages/growerpage.scss";
@import "./pages/press.scss";
@import "./pages/reviews.scss";
@import "./pages/coupon.scss";
@import "./pages/ambassadorpage.scss";

@import "./elements/contentelement.scss";
@import "./elements/farmmapelement.scss";
@import "./elements/twocolumnelement.scss";
@import "./elements/faqelement.scss";
@import "./elements/ctaelement.scss";
@import "./elements/ourprocesselement.scss";
@import "./elements/followuselement.scss";
@import "./elements/scrollingtext.scss";
@import "./elements/scrollingfaqs.scss";
@import "./elements/statselement.scss";
@import "./elements/mosaicgridelement.scss";
@import "./elements/InfoCardElement.scss";
@import "./elements/gridelement.scss";
@import "./elements/articlecarousel.scss";
@import "./elements/yolkelement.scss";
@import "./elements/whygrowerelement.scss";
@import "./elements/applynowgrowerelement.scss";
@import "./elements/growerapplyfooter.scss";
@import "./elements/reviews.scss";
@import "./elements/chickencarousel.scss";
@import "./elements/productcarousel.scss";
@import "./elements/largequoteelement.scss";
@import "./elements/scrollingvideo.scss";
@import "./elements/socialmediareviewselement.scss";
@import "./elements/image.scss";
@import "./elements/imagecontent.scss";
@import "./elements/videoelement.scss";
@import "./elements/historyelement.scss";
@import "./elements/ourrecipe.scss";
@import "./elements/ambassadorapplyelement.scss";
@import "./elements/couponelement.scss";
@import "./elements/hubspotembed.scss";


body {
	// @include palette-simple;
	margin: 0;
    // font-family: filson-soft, sans-serif;
    font-family:'Poppins', sans-serif;
}

.container.narrow {
    max-width:1000px;
}

img {
    max-width: 100%;
    height: auto;
}

.logo-blue {display:none;}

.btn-find-eggs {
    position:fixed;
    bottom:10px;
    left:20px;
    z-index:55;
    width:calc(100% - 40px);
    @include media-breakpoint-up(md) {
        display:none;
    }
}

// .element {
//     position: relative;
//     z-index:5;
// }

.cms-edit-link {
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:250;
    color: #fff;
    &:hover {color:#fff;}

    svg {fill:#fff; width:20px;}
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.z-10 {
    position: relative;
    z-index:10;
}


[animate] {
    transform:translateY(70px);
    opacity:0;
    transition:all 0.5s ease-in-out;

    &.in-view {
        transform:translateY(0);
        opacity:1;
    }
}
