form {
    label {
        color:#626554;
        font-size:14px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    input[type="text"],input[type="email"],input[type="tel"],textarea, select {
        border:1px solid #D0D0D0;
        border-radius:30px;
        display: inline-block;
        font-size: 0.875rem;
        padding: 0.7rem 1rem;
        width: 100%;
    }

    ::placeholder {
        color:#D0D0D0;
    }


    textarea {
        min-height:90px;
    }

    input[type="submit"] {
        background-color:#FE9016;
        border:none;
        &:hover {
            background-color:#FEA23C;
        }
    }
}