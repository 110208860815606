.product-carousel-wrap {
    position: relative;
    overflow-x:clip;
    .mask {
        position:absolute;
        bottom:-1px;
        left:0px;
        width:100%;
        z-index:10;
    }

    .mask-top {
        transform:scale(-1);
        top:-1px;
    }

    .accent-image {
        position:absolute;
        z-index:12;
    }

    .chilaquiles {
        bottom: -20px;
        right: calc(50% - 300px);
        width: 180px;
        @include media-breakpoint-up(md) {
            bottom:0px;
            right:calc(50% - 390px);
            width:230px;
        }
    }

    .over-easy {
        top: -20px;
        left: calc(50% - 290px);
        width: 180px;
        @include media-breakpoint-up(md) {
            top:30px;
            left:calc(50% - 340px);
            width:200px;
        }
    }

}
.product-carousel {
    // height:950px;
    .product-slide {
        position: relative;
        height: 100%;
        width:400px;
        @include media-breakpoint-up(md) {
            width:650px;
        }

        &.is-active {
            z-index: 1;
            .title {
                font-size:50px;
                @include media-breakpoint-up(md) {
                    font-size:80px;
                }
            }
            .review-snippet, .btn {
                opacity:1;
                transform:translateY(0px);
            }
            .carton {
                transform:scale(1.1);
                @include media-breakpoint-up(md) {
                    transform:scale(1.5);  
                }
            }
        }

        &:hover {
            .bg {transform:scale(1.25);}
        }
    }

    .carton {
        width:230px;
        margin-bottom:35px;
        transition:all 0.4s;
        transition-delay: 200ms;
        @include media-breakpoint-up(md) {
            width:290px;
        }
    }

    .bg {
        width: 100%;
        height:650px;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHZpZXdCb3g9IjAgMCA1NjAgNjgwIiBmaWxsPSJub25lIj4KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNNTQ5Ljg3OSA1LjYzNzc4TDIzLjkzMTMgMS4xNDc0NEMyMy45MzEzIDEuMTQ3NDQgLTIyLjUyNTYgMTc5LjY4MiAxNS42Mzc2IDMzMy43MzNDNTMuODAwOCA0ODcuNzgzIDcuMzQzODMgNjY2LjMxOCA3LjM0MzgzIDY2Ni4zMThMNTMzLjA3NyA2NzkuNDI4QzUzMy4wNzcgNjc5LjQyOCA1ODQuNDk5IDQ2Mi4yMDQgNTQxLjQ3OCAzNDIuNTMzQzQ5OC40NTcgMjIyLjg2MyA1NDkuODc5IDUuNjM3NzggNTQ5Ljg3OSA1LjYzNzc4WiIgLz4KPC9zdmc+");
        // -webkit-mask-size: 150px, 200px;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -webkit-mask-composite: exclude;
        transform: scale(1.15);
        transition:all 0.2s;

        @include media-breakpoint-up(md) {
            height:950px;
        }
    }

    .slide-content {
        position: absolute;
        width: 100%;
        z-index: 5;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height:100%;
    }

    .title {
        font-size:30px;
        color:$white;
        transition:all 0.4s;
        @include media-breakpoint-up(md) {
            font-size:50px;
        }   
    }
    .subtitle {
        font-size:20px;
        color:#FFB71B;
    }
    p {
        font-size:16px;
        color:$white;
    }
    .stars {
        margin:0 10px;
        display:inline-block;
        background:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTUuMDkgOC4yNkwxMiAyTDguOTEgOC4yNkwyIDkuMjdMNyAxNC4xNEw1LjgyIDIxLjAyTDEyIDE3Ljc3TDE4LjE4IDIxLjAyTDE3IDE0LjE0TDIyIDkuMjdMMTUuMDkgOC4yNloiIGZpbGw9IiNGNzkzMjAiIHN0cm9rZT0iI0Y3OTMyMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+");
        height:24px;
    }
    .btn {
        transition:all 0.4s;
        opacity:0;
        transform:translateY(20px);
    }
    
    .splide__pagination {
        transform: translateY(-365px);

        .splide__pagination__page {
            background:#fff;
            opacity:1;
            &.is-active {
                transform: scale(1.4) !important;
                background:$orange;
            }
        }

        // li {
        //     &:nth-child(2) {
        //         .splide__pagination__page {
        //             transform: scale(.8);
        //         }
        //     }
        //     &:nth-child(3) {
        //         .splide__pagination__page {
        //             transform: scale(.6);
        //         }
        //     }
        //     &:nth-child(4) {
        //         .splide__pagination__page {
        //             transform: scale(.4);
        //         }
        //     }
        //     &:nth-child(5) {
        //         .splide__pagination__page {
        //             transform: scale(.2);
        //         }
        //     }
        // }

    }

}

.cursor {
    display:none;
    justify-content:center;
    align-items:center;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 1px solid #fff;
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(calc(-50% + 15px), -50%);
    z-index:20;

    &.left svg {
        transform:scaleX(-1);
    }

    &.btn-hover {
        border-color:transparent;
        svg {display:none;}
    }
  }
  
  .cursor2 {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: black;
    opacity: .3;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width .3s, height .3s, opacity .3s;
    z-index:20;
  }

  .review-snippet {
    padding-bottom:20px;
    transition: all .4s;
    opacity: 0;
    transform: translateY(20px);

    .p-w-r {
        font-family: 'filson-soft';
        color:#fff;

        .pr-snippet-stars {
            background:transparent;
        }
        .pr-snippet .pr-snippet-stars-png {
            display: flex;
            flex-direction: row-reverse;
        }

        .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
            color: #fff;
            font-size: 20px;
            font-family:'Poppins';
            line-height: 28px;
            border:none;
            padding:0px 10px 0px 0px;
        }
        .pr-category-snippet__total {
            padding-left: 5px;
            font-family:'Poppins';
        }

        .pr-star-v4 {
            width:24px !important;
            height:24px !important;
            padding:0px !important;
            background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTUuMDkgOC4yNkwxMiAyTDguOTEgOC4yNkwyIDkuMjdMNyAxNC4xNEw1LjgyIDIxLjAyTDEyIDE3Ljc3TDE4LjE4IDIxLjAyTDE3IDE0LjE0TDIyIDkuMjdMMTUuMDkgOC4yNloiIGZpbGw9IiNGNzkzMjAiIHN0cm9rZT0iI0Y3OTMyMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+);
        }
        .pr-star-v4-0-filled {
            width:0px;
        }
        .pr-star-v4-25-filled {
            width:6px;
        }
        .pr-star-v4-50-filled {
            width:12px;
        }
        .pr-star-v4-75-filled {
            width:18px;
        }

    }
  }