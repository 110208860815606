.LocatorPage {

    .coupon-wrap {
        max-width: 750px;
        margin: 0px auto 40px;
        background: #ededed;
        border-radius: 15px;
        padding: 30px 20px;

        @include media-breakpoint-up(md) {
            padding: 30px;
        }
    }

    .text-opt-in {
        font-size: 12px;
        text-align: center;
        margin: 0px;
        padding: 20px 0px 0px;
    }

    .hbspt-form {
        form {
            position:relative;
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
            align-items:center;
            padding-bottom:70px;
            @include media-breakpoint-up(md) {
                padding-bottom:50px;
            }

            h3 {
                width:100%;
                text-align:center;
            }

            > div {
                width:100%;
            }
            .hs_email {
                width:100%;
                @include media-breakpoint-up(md) {
                    width:calc(60% - 10px);
                }
                input {
                    height:50px;
                }
            }

            .hs-opt_in {
                text-align:center;
                position:absolute;
                bottom:0px;
                margin:20px 0px 0;
                padding:0px;
                ul {
                    margin:0px;
                    padding:0px;
                    list-style-type:none;
                }
                li {
                    margin:0px;
                    padding:0px;    
                }
                label {
                    color:#1B1C47;
                    font-size:13px;
                }
                input {
                    margin-right:10px;
                }
            }

            .hs_submit {
                width:100%;
                margin-top:20px;
                @include media-breakpoint-up(md) {
                    width:40%;
                }
            }
            
            .hs-button {
                @extend .btn;
                @extend .btn-secondary;
                @extend .xwide;
                width:100%;
            }
        }  
    }
    
    .locator-wrapper {
        width:100%;
        max-width:1200px;
        padding:20px;
        margin:100px auto 0;
        height:1000px;
        @include media-breakpoint-up(md) {
            height:950px;
        }

        .wtb-inpage-embed {
            width:100%;
            height:100%;
        }
        iframe {
            width:100%;
            height:100% !important;
        }
    }

    .cartons-image {
        position: absolute;
        bottom: 90px;
        z-index: 10;
        width: 390px;
        transform: translateY(50%);
    }
}