h1,h2,h3,h4 {
    font-weight:800;
    font-family:'Qualy-Bold';
}

.heading-large {
    font-size:50px;
    line-height:55px;
    @include media-breakpoint-up(md) {
        font-size:80px;
        line-height:85px;
    }
}

.heading-xlarge {
    font-size:50px;
    line-height:55px;
    @include media-breakpoint-up(md) {
        font-size:80px;
        line-height:85px;
    }
    @include media-breakpoint-up(lg) {
        font-size:120px !important;
        line-height:125px !important;
    }
}

.text-orange {
    color:$orange !important;
}
.text-blue {
    color:$blue !important;
}
.text-sunny {
    color:$sunny !important;
}
.text-yellow {
    color:$yellow !important;
}
.text-small {
    font-size:14px;
}
.text-large {
    font-size:20px;
}