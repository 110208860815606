.SocialMediaReviews {
    position: relative;
    overflow-x: clip;
}
.socialmediareview-item {
        background-color: rgba(255, 87, 0, 0.05);
        width: 400px;
        border-radius: 12px;
        align-items: center;
        padding: 10px;

        .p {
            margin-bottom: none;
        }
        @include media-breakpoint-up(md) {
            width: 380px;
        }
        .social-media-img {
            width: 22px;
            height: 22px;
            border-radius: 25px;
            border: 1px solid lightgray;
            background-color: lightgray;
            overflow: hidden;
            img {
                vertical-align:top;
            }
        }
}

.socialmediareviews-container {
    padding:20px;
    margin:30px auto;
    overflow-x: hidden;
    width:100%;

    h2 {
        font-size: 50px;
        color: $orange;
        @include media-breakpoint-up(md) {
            font-size: 80px;
        }
    }
    h4 {
        color: $orange;
        font-family: 'Poppins', sans-serif;
    }
    @include media-breakpoint-up(md) {
        overflow: hidden;
    }
}
