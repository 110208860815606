// :root {
//     --palette-heading: #3C441D;
// }
.accordion {
    --bs-accordion-btn-icon:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23546122'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    --bs-accordion-btn-active-icon:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FE9016'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    --bs-accordion-btn-icon-width:25px;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 1.5rem;
}

input:focus,
select:focus, .form-control:focus,
textarea:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.pagination {
    .page-link {color:var(--palette-fire-1);}
    .page-link.active, .active>.page-link {
        z-index: 3;
        color: #fff;
        background-color: var(--palette-fire-1);
        border-color: var(--palette-fire-1);
    }
}