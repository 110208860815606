
.ChooseHappyPage {
    overflow-x: hidden;
    .page-heading {
        margin-bottom: 100px;
    }

    .accent-egg-CH-page{
        position:absolute;
        z-index: 6;
        top: 1880px;
        left: 30px;
        max-width: 110px;
        // Example
// Apply styles starting from medium devices and up to extra large devices
        @media (min-width: 250px) and (max-width: 349px) {
            top: 1950px;
        }
        @media (min-width: 350px) and (max-width: 450px) {
            top: 1890px;
        }
        @media (min-width: 451px) and (max-width: 570px) {
            top: 1800px;
        }
        @include media-breakpoint-up(sm) {
            top: 1780px;
            left: 20px;
        }
        @include media-breakpoint-up(md) {
            top: 1550px;
            max-width: 200px;
            left: 150px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 210px;
            left: 350px;
        }

    }



    // Choose Happy Scrolling Video section //

    .video-container {
        position:relative;
        background-image: url(../../images/Blue-gradient-choosehappy.webp);
        background-size: cover;
        min-height: 1100px;
        margin-top: -150px;

    }

    // Choose Happy Main Content Area //


}
