.faq_element, .faq-container {

    .accordion {
        border: none;

        .accordion-button {
            background-color: #F8F8F8;
            color: #000;
            border-radius: 20px;
            transition: color 0.2s;
            border: none;
            font-size: 30px;
            font-weight: 700;
            padding: 15px;
        }

        .accordion-button:not(.collapsed) {
            color: #FF5700;
            background-color: #F8F8F8;
            border: none;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;
        }

        .accordion-header {
            border: none;
        }
        .accordion-button {
            color:$dark-blue;
        }

        .accordion-body {
            background-color: #F8F8F8;
            border: none;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

        }

        .accordion-item {
            margin-bottom: 15px;
            border: none;
        }
    }

}


