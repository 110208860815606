.btn {
    color:$white;
	// border-radius: 24px;
	// padding-top: $btn-padding-y + 1;
	// padding-bottom: $btn-padding-y - 1;
	// --bs-btn-color: $white;
	// --bs-btn-hover-color: $white;
	// --bs-btn-hover-bg: var(--palette-foreground);
}


.btn-sunny, .btn-primary, .btn-secondary  {
    color:$white !important;
    &:hover {color:$white;}
}

.btn-pale {
    background:#FAEFDE;
    color:$blue;
    &:hover {background:#fdf7ee;color:$blue;}
}

.btn-light {
    color:$blue;
}

.btn-outline-primary {
    color:$orange;
    &:hover {color:#fff;}
}

// .btn-primary, .hs-button.primary {
// 	--bs-btn-color: var(--palette-accent-text);
// 	--bs-btn-bg: var(--palette-primary);
// 	--bs-btn-border-color: var(--palette-primary);
// 	--bs-btn-hover-color: var(--palette-accent-text);
// 	--bs-btn-hover-bg: var(--palette-secondary);
// 	--bs-btn-hover-border-color: var(--palette-secondary);
// 	--bs-btn-active-color: var(--palette-accent-text);
// 	--bs-btn-active-bg: var(--palette-secondary);
// 	--bs-btn-active-border-color: var(--palette-secondary);
// 	--bs-btn-disabled-color: var(--palette-text);
// 	--bs-btn-disabled-bg: var(--palette-primary);
// 	--bs-btn-disabled-border-color: var(--palette-primary);
// }

// .btn-outline-primary {
// 	--bs-btn-color: var(--palette-primary);
// 	--bs-btn-bg: transparent;
// 	--bs-btn-border-color: var(--palette-primary);
// 	--bs-btn-hover-color: var(--palette-text);
// 	--bs-btn-hover-bg: var(--palette-primary);
// 	--bs-btn-hover-border-color: var(--palette-primary);
// 	--bs-btn-active-color: var(--palette-text);
// 	--bs-btn-active-bg: var(--palette-primary);
// 	--bs-btn-active-border-color: var(--palette-primary);
// 	--bs-btn-disabled-color: var(--palette-primary);
// 	--bs-btn-disabled-bg: transparent;
// 	--bs-btn-disabled-border-color: var(--palette-primary);

//     &:hover {color:#fff;}
// }

.btn-accent {
	--bs-btn-color: var(--palette-text);
	--bs-btn-bg: var(--palette-accent-primary);
	--bs-btn-border-color: var(--palette-accent-primary);
	--bs-btn-hover-color: var(--palette-text);
	--bs-btn-hover-bg: var(--palette-accent-secondary);
	--bs-btn-hover-border-color: var(--palette-accent-secondary);
	--bs-btn-active-color: var(--palette-text);
	--bs-btn-active-bg: var(--palette-accent-secondary);
	--bs-btn-active-border-color: var(--palette-accent-secondary);
	--bs-btn-disabled-color: var(--palette-text-primary);
	--bs-btn-disabled-bg: var(--palette-accent-primary);
	--bs-btn-disabled-border-color: var(--palette-accent-primary);
}

.btn-link {
	--bs-btn-color: var(--palette-primary);
	--bs-btn-bg: transparent;
	--bs-btn-border-color: transparent;
	--bs-btn-hover-color: var(--palette-primary);
	--bs-btn-hover-bg: transparent;
	--bs-btn-hover-border-color: transparent;
	--bs-btn-active-color: var(--palette-primary);
	--bs-btn-active-bg: transparent;
	--bs-btn-active-border-color: transparent;
	--bs-btn-disabled-color: var(--palette-primary);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: transparent;
	text-decoration: none;
	font-weight: 700;
}

.btn-arrow {
	display: inline-flex;
	align-items: center;

	&::after {
		content: "";
		margin-left: 12px;
		height: 15px;
		width: 15px;
		background-color: currentColor;
		mask-size: contain;
		mask-repeat: no-repeat;
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.013' height='14.633' viewBox='0 0 15.013 14.633'%3E%3Cpath id='Icon_awesome-arrow-right' data-name='Icon awesome-arrow-right' d='M6.383,3.627l.744-.744a.8.8,0,0,1,1.136,0l6.514,6.51a.8.8,0,0,1,0,1.136L8.263,17.044a.8.8,0,0,1-1.136,0L6.383,16.3A.805.805,0,0,1,6.4,15.151L10.434,11.3H.8a.8.8,0,0,1-.8-.8V9.427a.8.8,0,0,1,.8-.8h9.63L6.4,4.777A.8.8,0,0,1,6.383,3.627Z' transform='translate(0 -2.647)'/%3E%3C/svg%3E%0A");
	}
}
.btn-arrow-reversed {
	display: inline-flex;
	align-items: center;

	&::after {
		content: "";
		margin-left: 12px;
		height: 15px;
		width: 15px;
		background-color: currentColor;
		mask-size: contain;
		mask-repeat: no-repeat;
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.013' height='14.633' viewBox='0 0 15.013 14.633'%3E%3Cpath id='Icon_awesome-arrow-right' data-name='Icon awesome-arrow-right' d='M6.383,3.627l.744-.744a.8.8,0,0,1,1.136,0l6.514,6.51a.8.8,0,0,1,0,1.136L8.263,17.044a.8.8,0,0,1-1.136,0L6.383,16.3A.805.805,0,0,1,6.4,15.151L10.434,11.3H.8a.8.8,0,0,1-.8-.8V9.427a.8.8,0,0,1,.8-.8h9.63L6.4,4.777A.8.8,0,0,1,6.383,3.627Z' transform='translate(0 -2.647)'/%3E%3C/svg%3E%0A");
        transform: rotate(180deg);
    }
}


.btn-large, .hs-button.large {
	padding-top: $btn-padding-y + 7;
	padding-bottom: $btn-padding-y + 5;
	border-radius: $btn-padding-y + 7px + 9px;
}

.btn.wide {
    padding-left:35px;
    padding-right:35px;
}
.btn.xwide {
    min-width:180px;
}
