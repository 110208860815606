.WhyGrowerElement {
    min-height: 1400px;
    margin-top: -120px;

    h2 {
        color: #FF5700;

    }

    .why-grower-vector {
        position: absolute;
        left: 0px;
        top: 2150px;
        max-height: 927px;
        max-width: 877px;
        z-index: -1;
        padding-left: unset;
    }
}

