.reviews.element {
    margin-bottom:120px;
    @include media-breakpoint-up(lg) {
        margin-bottom:160px;
    }

    .reviews-header {
        display:flex;
        align-items:center;
        justify-content:center;
        padding-top:30px;
        text-align:center;

        .stars {margin:0 20px;}

        .reviews-avg {
            display:inline-block;
            font-size:50px;
            line-height:55px;
            color:$blue;
            margin:0px;
        }
        .reviews-count {
            display:inline-block;
            font-size:20px;
            line-height:25px;
            color:$blue;
            margin:0px;
        }
    }
    .nav {
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:100%;
        max-width:538px;
        margin:70px auto 50px;
        flex-wrap:wrap;

        .btn {
            margin:0 5px 10px;
        }

    }


    .marquee {
        display: flex;
        width: 100%;
        overflow: hidden;
        text-align: left;
        margin: 0;
        white-space: nowrap;
    }

    .line {
        display: inline-block;
        animation: marquee 25s linear infinite;
        color: $orange;
        text-align: center;
        font-size: 80px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        @include media-breakpoint-up(md) {
            font-size: 120px;
        }
    }


    .reviews-container {
        width:100%;
        max-width:780px;
        padding:20px;
        margin:30px auto;
    }
    .review-item {
        border-bottom:1px solid $blue;
        padding-bottom:20px;
        margin-bottom:30px;

        .review-title {
            font-size:30px;
            margin:10px 0 20px;
        }

        .date {
            display:inline-block;
            font-size:12px;
            padding-left:10px;
            margin:0px;
        }
        p {color:$blue;}
    }
    .stars {
        display:inline-block;
        background:url("data:image/svg+xml;base64,ICAgICAgICAgICAgPHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS4wOSA4LjI2TDEyIDJMOC45MSA4LjI2TDIgOS4yN0w3IDE0LjE0TDUuODIgMjEuMDJMMTIgMTcuNzdMMTguMTggMjEuMDJMMTcgMTQuMTRMMjIgOS4yN0wxNS4wOSA4LjI2WiIgZmlsbD0iI0ZGNTcwMCIgc3Ryb2tlPSIjRkY1NzAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogICAgICAgICAgICA8L3N2Zz4K");
        height:24px;
    }

    @keyframes marquee {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100%, 0);
        }
    }

}
