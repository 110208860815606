#footer {
    position:relative;
    padding-top:140px;
    padding-bottom:20px;
    background: url(/_resources/themes/happyegg/images/footer-background-mobile.png) no-repeat;
    background-size:cover;
    color:$white;
    z-index: 2;

    @include media-breakpoint-up(md) {
        padding-top:160px;
        background-image: url(/_resources/themes/happyegg/images/footer-background.png);
    }

    .logo {
        margin-bottom:30px;
    }
    .btn-buy {
        width:200px;
    }

    .nav-link {
        display:block;
        color: #FFF;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration:none;
        padding:0 0 12px;
    }
    .nav-primary {
        font-size: 20px;
    }
    .nav-secondary {
        font-size: 16px;
        padding:0 0 15px;
    }

    .social {
        margin-bottom:25px;

        &.mobile {
            margin:35px 0;
        }
        a {padding:0 4px;}
        svg {
            fill:$white;
            height:20px;
        }
    }

    .col-nav {
        margin-bottom:30px;
    }

    .footer-right-col {
        display:none;
        @include media-breakpoint-up(md) {
            text-align:right;
            display:block;
        }
    }

    .footer-top-row {
        @include media-breakpoint-down(md) {
            text-align:center;
        }
    }

    .footer-bottom-row {
        padding-top:40px;
        text-align:center;
        @include media-breakpoint-up(md) {
            padding-top:80px;
            text-align: left;
        }

        p {font-size:12px;}

        .made-by {
            text-align:right;
        }
        a {color:$white;text-decoration:none;}
    }

    .footer-background {
        position:absolute;
        top:0px;
        width:100%;
        z-index:-1;
    }
}
