.element.image-content {
	display: flex;
	max-width: 1166px;
	margin: 150px auto;
	box-sizing: content-box;
	padding: 0 25px;
	gap: 25px;
	align-items: flex-start;

	@media screen and (max-width: 700px) {
		flex-direction: column;
		align-items: center;
	}

	&.flip {
		@media screen and (min-width: 701px) {
			flex-direction: row-reverse;
		}
	}

	img {
		border-radius: 10px;
		max-width: 50%;
		height: auto;

		@media screen and (max-width: 700px) {
			max-width: 100%;
		}
	}

	.content-wrapper {
		max-width: 512px;
		margin: 0 auto;

		.label {
			font-size: 18px;
			line-height: 1em;
			text-transform: uppercase;
			color: #faa42e;
			margin: 0 0 36px 0;
		}

		.heading {
			font-family: HWTArtz, sans-serif;
			font-size: 80px;
			line-height: 66px;
			color: #fd8f25;
			margin: 0 0 22px 0;
			text-transform: uppercase;

			@media screen and (max-width: 980px) {
				font-size: 48px;
				line-height: 40px;
			}
		}

		.content {
			font-size: 20px;
			line-height: 30px;
			color: #0e255a;
			margin: 0 0 22px 0;

			@media screen and (max-width: 980px) {
				font-size: 16px;
				line-height: 24px;
			}
		}

		.button {
            @extend .btn;
			// @include button.button();
			background-image: linear-gradient(to bottom, #96d9e9, #6bcde5);

			&:hover {
				background-image: linear-gradient(to bottom, hsl(192, 65%, 65%), hsl(192, 70%, 56%));
			}
		}
	}
}