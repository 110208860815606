.ProductDetailPage {

    // Heading Override
    .page-heading {
        @include media-breakpoint-down(md) {
            height:450px;
        }

        &.heritage {
            @include media-breakpoint-down(md) {
                height:520px;
            }
    
        }

        h1 {
            @include media-breakpoint-up(md) {
                font-size: 120px;
                line-height: 120px;
            }
        }
        .carton-image {
            position: absolute;
            bottom: 20px;
            transform:translateY(50%);
            z-index: 10;
            @include media-breakpoint-up(sm) {
                bottom: 20px;
            }    
            @include media-breakpoint-up(md) {
                bottom: 40px;
            }
            // @include media-breakpoint-up(lg) {
            //     bottom: 20px;
            // }
            img {
                width: 500px;
                @include media-breakpoint-up(md) {
                    width: 680px;
                }
                @include media-breakpoint-up(lg) {
                    width: 800px;                    
                }
            }

            .heritage-accent {
                position: absolute;
                z-index:10;
                right: 10px;
                top: 10px;
                width:90px;
                @include media-breakpoint-up(sm) {
                    top: 10%;
                    right: -10px;
                    width:100px;
                }
                @include media-breakpoint-up(md) {
                    top: 18%;
                    width:120px;
                }
            }
        }
    
    }
    .page-heading-content {padding:0 30px;}

    // Element Overrides

    .StatsElement {
        @include media-breakpoint-down(md) {
            background-image: url(/_resources/themes/happyegg/images/product-stats-mobile.png);
            background-size: 100% 100%;
            margin-top: 70px;
            margin-bottom: 70px;
            background-repeat:no-repeat;
            .stats-wrap {
                display:flex;
                flex-wrap:wrap;
            }

            .stat-item {
                width:50%;
            }

            .stat-label {
                color:#fff;
            }
        }
    }

    .StoreLocator {
        position:relative;
        .mask {
            display: block !important;
            position:absolute;
            left:0px;
            bottom:-1px;
            path {fill:#F8F8F8;}
        }
        .accent-egg-brown {
            bottom:-50px;
            z-index:10;
        }
    }
    .ArticleCarousel {
        background:#F8F8F8;
        margin-bottom:40px;
        padding:50px 0px;
        @include media-breakpoint-up(lg) {
            padding:70px 0 100px;
            margin-bottom:70px;
        }
        .bg-shape {
            display:none;
        }
        .mask {
            display: block;
            bottom: 0px;
            top: unset;
            position: absolute;
        }
    }
    
    .key-product-features {
        max-width:1180px;
        margin:0 auto;
        padding:60px 20px 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(sm) {
            padding:100px 20px 100px;
        }
        @include media-breakpoint-up(md) {
            padding:120px 20px 100px;
        }
        @include media-breakpoint-up(lg) {
            justify-content:space-between;
            align-items:center;
            padding:100px 20px 120px;
            flex-wrap: nowrap;
        }
    }
    .key-product-feature {
        position:relative;
        text-align:center;
        width: 115px;
        @include media-breakpoint-up(md) {
            width: 150px;
        }
        @include media-breakpoint-up(lg) {
            width:20%;
            max-width:220px;
            padding:0px;
            width:auto;
            &:nth-child(2), &:nth-last-child(2) {
                top:50px;
                // transform:translateY(50px);
              }
            &:nth-child(3)  {
                top:100px;
                // transform:translateY(100px);
            }    
        }
        img {max-width:110px;}
        // No longer in use
        // .icon {
        //     display:flex;
        //     align-items:center;
        //     justify-content:center;
        //     width:70px;
        //     height:70px;
        //     border-radius:50%;
        //     border:2px solid $orange;
        //     margin:0 auto;
        //     @include media-breakpoint-up(lg) {
        //         width:100px;
        //         height:100px;
        //     }
        // }
        .title {
            font-size:14px;
            line-height:18px;
            color:$orange;
            padding-top:10px;
            font-weight:700;
            @include media-breakpoint-up(md) {
                font-size:16px;
                line-height:20px;
            }
            @include media-breakpoint-up(lg) {
                font-size:24px;
                line-height:28px;    
            }
        }
    }

    .element.twocolumn .bg-shape {
        position: absolute;
        top: -70px;
        left: -60px;
        z-index: 4;
        transform: scale(.85);
    }
    
}
