.FAQPage {

    overflow-x: hidden;
    .search-bar {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 25px;
        padding: 2px 5px;
        background-color: #F8F8F8;
        @include media-breakpoint-up(md) {
            padding: 5px 10px;
        }
    }
    .search-input {
        flex: 1;
        border: none;
        outline: none;
        padding: 3px 7px;
        font-size: 14px;
        background-color: #F8F8F8;
        @include media-breakpoint-up(md) {
            padding: 5px 10px;
        }
    }

    #btn-search {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
    }


    #btn-search {
        display:flex;
        justify-content:center;
        align-items:center;
        border-radius:50%;
        width:50px;
        height:50px;
        background:$orange;
    }

    .cat-nav {
        .btn {margin:0px 5px 10px;}
    }
    .accordion-header {
        p {
            font-family:'Qualy-Bold';
            margin-bottom:0px;
        }
    }
}

.faq-container{

    .pagination-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        .current-page {
            color: white;
            background-color: #F7941D;
            border: 1px solid #F7941D;
            border-radius: 50px;
            padding: 8px;
            width: 40px;
            height: 40px;
            text-align: center;
        }

        #page-number {
            color: #1B1C47;
            text-decoration: none;
        }
    }
}

.noresults-faq {
    text-align: center;
    pointer-events: none;
    //opacity: 0;
    z-index: 1;
    background: #FFF;
    box-shadow: 0px 40px 120px 0px rgba(27, 28, 71, 0.20);
    background-image: url('../../images/Vector-success.svg');
    background-repeat: no-repeat;
    width: 555px;
    min-height: 600px;
    flex-shrink: 0;
    border-radius: 20px;
    @include media-breakpoint-up(md) {
        min-height: 647px;
    }

.noresults-image {
    position: relative;
    // opacity: 0;
    transition: opacity .3s .3s;
    margin-top: 50px;

    svg {
        position: relative;
        height: 100%;
        width: 100%;
    }
    .noresults-heading {
        margin: 14px 0;
        font-size: 50px;
        font-weight: 800;
        line-height: normal;
        color: #FF5700;
        //opacity: 0;
        transform: translateY(40px);
        transition: transform .5s .3s, opacity .5s .3s;

        @media screen and (max-width: 500px) {
            font-size: 16vw;
        }
    }
    .noresults-message {
        font-family: filson-pro, sans-serif;
        margin: 0;
        font-size: 24px;
        line-height: 35ox;
        color: #0e255a;
        //opacity: 0;
        transform: translateY(40px);
        transition: transform .7s .3s, opacity .7s .3s;
    }

    .faq-accent-egg-noresults {
        position: absolute;
        bottom: -170px;
        left: -25px;
        max-width: 120px;
        @include media-breakpoint-up(md) {
            bottom: -200px;
            max-width: 140px;
        }
    }
    .falling-egg-noresults {
        position: absolute;
        top: -90px;
        right: -100px;
        transform: rotate(5.607deg);

    }

}
}

