.AmbassadorPage {

    overflow-x: hidden;

    .ambassador-heading-title h1 {
            color: #FFF;
            font-size: 35px;
            line-height: 38px;
            font-style: normal;
            font-weight: 800;
            overflow-wrap: break-word;

            @media (min-width: 310px) {
                font-size: 50px;
                line-height: 53px;
            }
            @include media-breakpoint-up(md) {
                font-size: 80px;
                line-height: 88px;
            }
    }


    .AmbassadorImagesElement {
        padding-top: 50px;
        margin-bottom: -255px;
        background: url(../../images/ambassador/mobile-bg.png);
        background-repeat: no-repeat;
        background-size: auto;
        z-index: 5;
        position: relative;


        @include media-breakpoint-up(sm) {
                background-size: cover;
                margin-bottom: -172px;
            }
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            background: none;
            z-index: 2;
            }


        }

        .ambassador-icon {
            max-width: 90px;
            height: auto;
        @include media-breakpoint-up(md) {
            max-width: 250px;
            }
        }


    .ambassador-award-icon img {
        position: absolute;
        z-index: 39;
        right: 30px;
        top: 590px;
        max-width: 150px;

        @include media-breakpoint-up(md) {
                top: 500px;
                max-width: 190px;
            }

        @include media-breakpoint-up(xxl) {
                top: 500px;
            }
        }

    #orange-vector-ambassador {
        margin-top: -45px;
        min-height: 80px;
        z-index: 38;
        position:relative;

        @include media-breakpoint-up(md) {
                margin-top: -75px;
            }

        @include media-breakpoint-up(xl) {
                margin-top: -65px;
                width: 100%;
            }
        @include media-breakpoint-up(xxl) {
                margin-top: -62px;
            }
        @media (min-width: 1600px) {
                margin-top: -70px;
                margin-right: -50px;

            }

    }
    .ambassadorimagestitle {
        position: relative;
        z-index: 50;
        color: white;
        @include media-breakpoint-up(md) {
                color: $orange;
            }
        @include media-breakpoint-up(xl) {
                margin-bottom: -40px;
            }

    }

    .orange-transparent-vector img{
        position: absolute;
        bottom: 400px;
        z-index: 51;
        width: 100%;
        visibility: hidden;
    }
    .white-curve-vector {
        position: absolute;
        bottom: 380px;
        width: 130%;

        @media only screen and (min-width: 889px) {
                bottom: 425px;
            }

        @include media-breakpoint-up(lg) {
                bottom: 490px;
                width: 115%;
            }
        @media only screen and (min-width: 1110px) {
                    bottom: 535px;
                }
        @include media-breakpoint-up(xl) {
                bottom: 580px;
                width: 100%;
            }
        @media only screen and (min-width: 1275px) {
                bottom: 640px;
            }

        @include media-breakpoint-up(xxl) {
                bottom: 690px;
            }
        @media (min-width: 1510px) {
                bottom: 740px;
                }
        @media (min-width: 1600px) {
                bottom: 800px;
                }
        @media (min-width: 1750px) {
                bottom: 880px;
                }

    }
    .white-curve-vector img {
            width: 100%;
        }
    .ambassador-images {
        margin-bottom: -170px;
    }
        .image-tile {
                width: 315px !important;
                padding: 0 10px;

                a {
                    display: block;
                    text-decoration: none;
                    padding-top: 10px;
                }

                img {
                    border-radius: 20px;
                    box-shadow: 0px 0px 0px $orange;
                    transition: all .2s;
                }

                .title {
                    text-decoration: none;
                    color: $dark-blue;
                    padding: 15px 0;
                }

                &:hover {
                    .title {
                        color: $orange;
                    }

                    img {
                        transform: translate(-10px, -10px);
                        box-shadow: 10px 10px 0px $orange;
                    }
                }

            }
    }


