.ArticleCarousel {
    overflow-x:clip;

    .mask {display:none;}

    .article-tile {
        width:325px !important;
        padding:0 10px;
        a {
            display:block;
            text-decoration:none;
            padding-top:10px;
        }

        &.odd {
            transform:translateY(50px);
        }

        img {
            border-radius:20px;
            box-shadow:0px 0px 0px $orange;
            transition: all .2s;
        }

        .title {
            text-decoration: none;
            color:$dark-blue;
            padding:15px 0;
        }

        &:hover {
            .title {
                color:$orange;
            }

            img {
                transform:translate(-10px,-10px);
                box-shadow:10px 10px 0px $orange;
            }
        }

    }

    // When the "Carousel" boolean field is unchecked/disabled
    .article-grid {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px;
        position: relative;
    }

    .bg-shape {
        position: absolute;
        right: -90px;
        top: -50px;
        transform: scale(.85);
    }

    .subheading {
        text-align: center;
        font-size:24px;
        font-weight: 700;
        margin:20px 0;
    }
}
