.ScrollingText {
    margin-top: 150px;
    margin-bottom: 150px;
}

.container-fluid {
    justify-content: center;


    .scroll-text {
        white-space: nowrap;
        overflow: hidden;
        animation: scroll-text 25s linear infinite;
        color: #FF5700;
        font-size: 6vw;
        font-weight: 800;
        position: relative;
        animation-timing-function: linear;
        margin-right: 200px;
    }

    @keyframes scroll-text {
        0% {
            transform: translateX(0,0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    .scrolling-box {
        width: 100%;
        flex-wrap: nowrap;
        justify-content: center;
        margin-bottom: 50px;
    }

    .scroll-element-content {
        font-size: 24px;
        justify-content: center;
        margin-bottom: 50px;
    }

    .chat-now {
        padding: 14px 15px 10px 15px;
        border-radius: 25px;
        background: var(--Amber-Orange, #FF5700);
        color: white;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 156px;
        height: 50px;
    }
    .chat-now-row {
        justify-content: center;
        margin-bottom: 50px;
    }


}
