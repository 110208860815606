.Article {
    .sidebar {display:none;}

    .content-wrapper {
        max-width:690px;
        margin:0 auto;
        padding:20px;

        @include media-breakpoint-up(md) {
            padding-top:40px;
        }

    }

    .reviews.mobile {display:none;}

    .element.content {
        max-width:690px;
        margin:0 auto;
        padding:20px;
        display:none;
    }

    .article-body {
        padding-bottom:150px;
    }

}

