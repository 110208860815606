.element.twocolumn {
    position:relative;
    overflow-x:clip;
    @include media-breakpoint-up(lg) {
        padding:80px 0;
    }

    .bg-shape {
        position:absolute;
        top:-140px;
        left:0;
        // width:100%;
        // height:100%;
        z-index:4;
    }
    .bg-shape-right {
        position: absolute;
        top: -220px;
        right: -110px;
        z-index: 4;
        transform: scale(.85);
    }

    .content-wrap {
        position: relative;
        z-index: 5;

        &.pad-left {
            @include media-breakpoint-up(xl) {
                padding-left:100px;
            }
        }
        &.pad-right {
            @include media-breakpoint-up(xl) {
                padding-right:100px;
            }
        }

        h1,h2,h3,h4 {color:$orange;margin-bottom:20px;}

        p {
            font-size:20px;
            color:$navy-blue;
        }

    }

    .image-wrap {
        position:relative;
        z-index:5;
        margin-bottom:30px;

        @include media-breakpoint-up(lg) {
            margin-bottom:0px;
        }
        .image {
            border-radius:20px;
        }
    }

}