.item-grid {
    margin:20px 0;
    padding-bottom:60px;

    @include media-breakpoint-up(md) {
        margin:20px 0 40px;
        padding-bottom:20px;
    }

    img {
        border-radius:20px;
    }
    
    .splide__pagination {
        bottom: -40px;
    }

    .splide__pagination__page {
        background: #d9d9d9;
        &.is-active {
            background: $orange;
        }
    }

    .splide__slide {
        text-align:center;
        padding:0 12px;
    }
}