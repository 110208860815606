.ScrollingVideoElement {
    position: relative;
    width:100%;
    height:calc(100vh + 100px);
    overflow-x:clip;
    top:-280px;
    z-index:1;

    background-image: url(/_resources/themes/happyegg/images/scrolling-video-element-bg.png);
    background-size:cover;
    background-repeat:no-repeat;
    transition:all .5s;

    &.fixed {
        position:fixed;
        top:100px;
        left:0px;
        z-index:100;
        height:100vh;

    }

    .mask {
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        z-index:10;
    }

    .cursor {
        border-color:$sunny;
        color:$sunny;
    }

    #curve-text-container {
        position:absolute;
        width:120%;
        top:50%;
        left:-10%;
        transform:translateY(-50%);
        z-index:15;
        display:none;
        @include media-breakpoint-up(md) {
            display:block;
        }

        #curve-text-choose, #curve-text-happy {
            font-family:'Qualy-Bold';
            font-size:70px;
            font-weight:700;
            fill:#fff;
        }
    }

    #video-preview-wrapper {
        opacity:0;
        position:absolute;
        width: 50%;
        margin: 0 auto;
        border-radius: 20px;
        overflow: hidden;
        transition:all .5s;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;

        @include media-breakpoint-down(sm) {
            width:100% !important;
        }


        &.loaded {
            opacity:.4;
        }
        &:before {
            content:'';
            display:block;
            width:100%;
            height:100%;
            position:absolute;
            z-index:10;
        }
    }

    .choose-happy-curve-mobile {
        display:block;
        position:absolute;
        transform:translate(-50%, 0%);
        top:160px;
        left:50%;
        @include media-breakpoint-up(md) {
            display:none;
        }
    }

    #mobile-play-button {
        display: none;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        border: 1px solid $sunny;
        transition: all 200ms ease-out;
        transform: translate(-50%, -50%);
        z-index: 20;
        color:$sunny;
        position: absolute;
        top: 50%;
        left: 50%;
        &.active {
            display: flex;
        }
    }

}

// Fullscreen full-length video
#full-video-wrapper {
    width: 0px;
    height: 0px;
    position: fixed;
    z-index: 75;
    top: 50%;
    background: #000;
    padding-top: 80px;
    opacity: 0;
    overflow: hidden;
    transition: all .4s;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
        width: 100vw;
        height: 100vh;
        opacity:1;
    }

    #video-inner-wrapper {
        position:absolute;
        top:50%;
        transform:translateY(-50%);
    }

    iframe {
        max-width:100%;
    }

}
#btn-close-video {
    position:absolute;
    top:0px;
    right:0px;
    color:#fff;
    font-size: 40px;
    font-family: sans-serif;
    font-weight: 100;
    padding: 10px;
    z-index: 10;
    cursor: pointer;
}
