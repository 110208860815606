.element.video {
	display: flex;
	max-width: 960px;
	height: auto;
	max-height: 540px;
	margin: 80px auto;
	box-sizing: content-box;
	padding: 0 40px;
	justify-content: center;

	@media screen and (max-width: 800px) {
		padding: 0 20px;
	}

	video {
		max-width: 100%;
		max-height: 540px;
		width: auto;
		display: block;
		height: auto;
		border-radius: 10px;
	}

    &.full-width {
        max-width:1160px;
    }
}