#header {
    position:absolute;
    width:100%;
    z-index:40;
    padding-top: 38px;
    height:110px;
    position:fixed;
    @include media-breakpoint-up(md) {
        padding-top: 30px;
    }

    &:before {
        content:'';
        position:absolute;
        left:0px;
        top:0px;
        width:100%;
        height:100%;
		background-image: url(/_resources/themes/happyegg/images/header-background.png);
        background-size: cover;
        opacity:0;
        transition:all .2s;
        z-index:-1;
    }

    &.solid:before {
        opacity:1;
    }
    &.menu-open {
        // position:fixed;
        &:before {
            background:transparent;
        }

        .icon-menu {
            .bar:nth-child(3) {
                opacity: 0;
            }
            .bar:nth-child(2) {
                width: 28px;
                transform: translateY(9px) rotate(45deg);
            }
            .bar:nth-child(4) {
                width: 28px;
                transform: translateY(-9px) rotate(-45deg);
            }
        }
    }

    .icon-menu {
        display: block;
        // padding: 10px 18px 10px 0;
        transition: all .3s;
        cursor: pointer;
        .bar {
            display: block;
            width: 28px;
            height: 2px;
            margin: 7px 0;
            transition: all .4s;
            background: $white;
            &:nth-child(3) {
                transition: all .3s;
            }
        }
        &:hover {
            opacity: .8;
            // .bar:last-child {width:32px;}
        }
    }

    .logo {
        padding: 10px 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
    }

    .btn-store-locator {
        @include media-breakpoint-up(md) {
            display:none;
        }
    }

    #btn-shop {
        display:none;
        @include media-breakpoint-up(md) {
            display:block;
        }
    }

}


.basic-header {
    position: relative;
    z-index: 20;
    .logo {
        padding: 10px 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
    }
}